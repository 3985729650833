<template>
  <div class="medical-record-new">
    <base-loading :waitFlg="waitFlg" />
    <unsaved-leave-popup :layerNumber="4" />
    <div class="windows" ref="windows">
      <medical-content-input-form
        ref="medicalContentInputForm"
        v-show="mixinMedicalContentInputFormShowFlg"
        :patientId="patientId"
        :displayOwner="mixinOwner"
        :medicalRecord="medicalRecord"
        :medicalContent="medicalContent"
        :waitFlg="mixinWaitFlg"
        :isCheckingApply="mixinIsCheckingApply"
        :expansionDisplayFlg="mixinExpansionMedicalContentInputFormFlg"
        :dateDisabled="mixinDateChangeNg"
        :startEndMinMax="mixinStartEndMinMax"
        :overlappedTimeErrorMessage="mixinOverlappedTimeErrorMessage"
        :hospitalizationUpdateFlg="mixinHospitalizationUpdateFlg"
        :removeApplyFlgError="mixinRemoveApplyFlgError"
        @input-medical-record="mixinInputMedicalRecord"
        @click-menu-item="mixinShowKarteRelatedContent"
        @click-examination-result-table="mixinClickExaminationResultTable"
        @create-medical-content="createMedicalContent"
        @set-to-initial-medical-content="
          mixinSetToInitialMedicalContentAfterWatch
        "
        @edit-medical-content-image="mixinEditMedicalContentImage"
        @expansion="mixinExpansionMedicalContentInputForm"
        @reduction="mixinReductionMedicalContentInputForm"
        @unsaved-resized-image="unsavedResizedImage"
      />
      <medical-contents-history
        class="window"
        v-show="mixinMedicalContentsHistoryShowFlg"
        v-if="mixinMedicalContentsHistoryFlg"
        :patientId="patientId"
        :style="mixinMovedStyleMedicalContentsHistory"
        @failed-get-history-images="mixinFailedGetHistoryImages"
        @close="mixinCloseMedicalContentsHistory"
        @move="mixinMoveMedicalContentsHistory"
      />
      <div
        v-if="mixinMedicalContentsHistoryFootPrintFlg"
        class="foot-print window"
      ></div>
      <medical-payment-input-form
        class="window medical-payment-input-form"
        v-show="mixinMedicalPaymentInputFormShowFlg"
        :patientId="patientId"
        :patient="mixinPatient"
        :displayOwner="mixinOwner"
        :anicomPatient="mixinAnicomPatient"
        :ipetPatient="mixinIpetPatient"
        :medicalRecord="medicalRecord"
        :medicalPayment="medicalPayment"
        :medicalTreatmentItems="medicalTreatmentItems"
        :anicomCIdCheck="mixinAnicomCIdCheck"
        :ipetCheck="mixinIpetCheck"
        :waitFlg="waitFlg"
        :isCheckingApply="mixinIsCheckingApply"
        :expansionFlg="mixinExpansionMedicalPaymentInputFormFlg"
        :style="mixinClearMargin"
        :pastHospitalizationMedicalPayments="
          mixinPastHospitalizationMedicalPayments
        "
        :priceDetail="mixinPriceDetail"
        :hospitalizationInfo="mixinHospitalizationInfo"
        :initialMedicalPayment="mixinInitialData.medicalPayment"
        :overlappedTimeErrorMessage="mixinOverlappedTimeErrorMessage"
        :hospitalizationUpdateFlg="mixinHospitalizationUpdateFlg"
        :removeApplyFlgError="mixinRemoveApplyFlgError"
        @click-medical-payments="
          mixinMedicalPaymentsHistoryFlg = !mixinMedicalPaymentsHistoryFlg
        "
        @input-apply-flg="mixinInputApplyFlg"
        @updated-insurance="mixinSetInsurance"
        @input-in-hospital-flg="mixinInputInHospitalFlg"
        @input-start-hospitalization-flg="mixinInputStartHospitalizationFlg"
        @input-middle-calculate-flg="mixinInputMiddleCalculateFlg"
        @input-end-hospitalization-flg="mixinInputEndHospitalizationFlg"
        @order="mixinChangeOrder"
        @click-trash="mixinRemoveItem"
        @add-items="mixinAddItems"
        @change-item="mixinChangeItem"
        @copy="mixinCopyFromEstimate"
        @expansion="mixinExpansionMedicalPaymentInputForm"
        @reduction="mixinReductionMedicalPaymentInputForm"
        @click-payment="handlePaymentNavigation"
        @decision="mixinCreateUpdateMedicalPayment"
      />
      <medical-payments-history
        class="window"
        v-show="mixinMedicalPaymentsHistoryShowFlg"
        v-if="mixinMedicalPaymentsHistoryFlg"
        :patientId="patientId"
        :style="mixinMovedStyleMedicalPaymentsHistory"
        @copy="mixinConfirmMedicalPaymentCopy"
        @close="mixinCloseMedicalPaymentsHistory"
        @move="mixinMoveMedicalPaymentsHistory"
      />
      <div
        v-if="mixinMedicalPaymentsHistoryFootPrintFlg"
        class="foot-print window"
      ></div>
    </div>
    <problem-list-popup
      v-if="mixinProblemListPopupFlg"
      :patientId="patientId"
      @close="mixinProblemListPopupFlg = false"
    />
    <upload-image-popup
      :patientId="patientId"
      v-if="mixinUploadImagePopupFlg"
      @close="mixinUploadImagePopupFlg = false"
      @decide="mixinSetUploadImage"
    />
    <schema-popup
      v-if="mixinSchemaPopupFlg"
      :patientId="patientId"
      :medicalContentImageId="mixinMedicalContentImageId"
      @close="mixinCloseSchemaPopup"
      @decide="mixinSetMedicalContentImage"
    />
    <examination-list-popup
      v-if="mixinExaminationListPopupFlg"
      :examinationResultGroups="examinationResultGroups"
      :examinationResultGroupsIncludeDel="examinationResultGroupsIncludeDel"
      :patientId="patientId"
      :medicalRecordDate="medicalRecord.date"
      @close="mixinExaminationListPopupFlg = false"
      @copy="mixinSetExaminationResults"
    ></examination-list-popup>
    <examination-popup
      v-if="mixinExaminationPopupFlg"
      :treatment="mixinChangeResultTreatment"
      :format="mixinChangeResultFormat"
      :changeExaminationResultGroupId="mixinChangeExaminationResultGroupId"
      :examinationResultGroups="examinationResultGroups"
      :patientId="patientId"
      @close="mixinCloseExaminationPopup"
      @copy="mixinChangeExaminationResults"
    ></examination-popup>
    <medical-content-templates-popup
      v-if="mixinMedicalContentTemplatesPopupFlg"
      @close="mixinMedicalContentTemplatesPopupFlg = false"
      @set="mixinSetTemplate"
    />
    <announce-popup
      class="medical-content-popup"
      v-if="mixinAlertFlg"
      :title="mixinTitle"
      :buttons="mixinButtons"
      :type="mixinType"
      @close="mixinCloseContentPopup"
      @cancel="mixinCloseAlertPopup"
      @decision="mixinPopup.decision"
      >{{ mixinPopupMessage }}</announce-popup
    >
    <announce-popup
      class="medical-payment-popup"
      v-if="popupFlg"
      :type="type"
      :title="title"
      :leftAlignMessage="leftAlignMessage"
      :buttons="buttons"
      :disabled="waitFlg"
      @close="mixinClosePaymentPopup"
      @cancel="mixinClosePaymentPopup"
      @decision="decision"
      >{{ popupMessage }}
      <template #button v-if="showValidityCheckButton">
        <div class="slot-button">
          <base-button-border-orange @click="mixinConfirmAnicomUrlOpen"
            >有効性確認システム</base-button-border-orange
          >
        </div>
      </template></announce-popup
    >
    <announce-popup
      v-if="warningPopup.isOpened"
      data-test="warning-popup"
      v-bind="warningPopup"
      @close="warningPopup.isOpened = false"
    />
    <kick-out-popup />
  </div>
</template>

<script>
import MedicalContentInputForm from '@/components/parts/organisms/MedicalContentInputForm'
import MedicalContentsHistory from '@/components/parts/organisms/MedicalContentsHistory'
import MedicalPaymentInputForm from '@/components/parts/organisms/MedicalPaymentInputForm'
import MedicalPaymentsHistory from '@/components/parts/organisms/MedicalPaymentsHistory'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import CommonMedicalContentNewEdit from '@/components/mixins/CommonMedicalContentNewEdit'
import ProblemListPopup from '@/components/popups/ProblemListPopup'
import UploadImagePopup from '@/components/popups/UploadImagePopup'
import SchemaPopup from '@/components/popups/SchemaPopup'
import ExaminationListPopup from '@/components/popups/ExaminationListPopup'
import ExaminationPopup from '@/components/popups/ExaminationPopup'
import KickOutPopup from '@/components/popups/KickOutPopup'
import MedicalContentTemplatesPopup from '@/components/popups/MedicalContentTemplatesPopup'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import MedicalPaymentNewEdit from '@/components/mixins/MedicalPaymentNewEdit'
import _ from 'lodash'
import moment from 'moment'
import { mapGetters } from 'vuex'
import { hasSameStartEnd, isStartEnd } from '@/utils/medical_payment_helper'
import { jpDate } from '@/utils/moment'

export default {
  name: 'MedicalRecordNew',

  components: {
    MedicalContentInputForm,
    MedicalContentsHistory,
    MedicalPaymentInputForm,
    MedicalPaymentsHistory,
    ProblemListPopup,
    UploadImagePopup,
    SchemaPopup,
    ExaminationListPopup,
    ExaminationPopup,
    AnnouncePopup,
    UnsavedLeavePopup,
    KickOutPopup,
    MedicalContentTemplatesPopup,
    BaseButtonBorderOrange,
    BaseLoading
  },

  mixins: [
    CheckInputDifference,
    CommonMedicalContentNewEdit,
    MedicalPaymentNewEdit
  ],

  props: {
    ownerId: { type: Number },
    patientId: { type: Number }
  },

  data() {
    return {
      medicalRecord: {},
      medicalContent: {
        patientId: this.patientId,
        content: '<p></p>'
      },
      examinationResultGroups: {},
      examinationResultGroupsIncludeDel: {},
      medicalPayment: {
        patientId: this.patientId,
        staffId: 0,
        diseaseClass1Id: 0,
        disease1Id: 0,
        diseaseClass2Id: 0,
        disease2Id: 0,
        insuranceType: '',
        applyFlg: 0,
        anicomCIdCheckId: 0,
        ipetCheckId: 0,
        rezeptCd: '',
        pledgeRate: 0,
        reason01: 0,
        reason02: 0,
        reason03: 0,
        reason04: 0,
        reason05: 0,
        reason06: 0,
        reason07: 0,
        reason08: 0,
        reason09Txt: '',
        onsetDate: '',
        onsetAroundFlg: 0,
        uncertainOnsetFlg: 0,
        surgeryFlg: 0,
        surgeryLimitFlg: 0,
        startHospitalizationFlg: 0,
        inHospitalFlg: 0,
        middleCalculateFlg: 0,
        endHospitalizationFlg: 0,
        discountRate: 0,
        discountPrice: 0,
        burdenAmount: 0
      },
      medicalTreatmentItems: [],
      hospitalizationDetail: {},
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      leftAlignMessage: '',
      buttons: [],
      decision: () => {},
      waitFlg: false,
      noDataFlg: false,
      showValidityCheckButton: false,
      paymentNewFlg: false,
      warningPopup: {
        isOpened: false,
        type: 'alert',
        title: '注意',
        leftAlignMessage: '',
        buttons: ['閉じる']
      }
    }
  },

  computed: {
    ...mapGetters({
      staffReservationColumns: 'staffReservationColumns/getData',
      medicalPaymentByOriginalId: 'medicalPayments/getDataByOriginalId'
    })
  },

  created() {
    // 予約ポップアップから遷移してきた場合、そのデータを参照する
    const reservation = this.$route.params.reservation
    if (reservation) {
      this.medicalRecord = {
        ownerId: this.ownerId,
        patientId: this.patientId,
        reservationId: reservation.id,
        date: reservation.date,
        startTime: reservation.startTime,
        endTime: reservation.endTime,
        reservationColumnId: reservation.reservationColumnId
      }
    } else {
      const today = moment()
      const { startTime, endTime } = this.calculateToFiveInMinutes(today)
      this.medicalRecord = {
        ownerId: this.ownerId,
        patientId: this.patientId,
        reservationId: 0,
        date: today.format('YYYYMMDD'),
        startTime,
        endTime,
        reservationColumnId: this.staffReservationColumns[0].reservationColumnId
      }
    }

    /*
     * 診療日から患者が入院中だと判定できる場合、以下の設定を行う ※簡易コメント
     *   1. 初期値は入院中（inHospitalFlg: 1）
     *   2. 直前の入院明細の「診断」「受傷日/発症日」を引き継ぐ
     */
    if (
      this.mixinHospitalizationInfo.current &&
      !this.medicalPayment.startHospitalizationFlg &&
      !this.medicalPayment.endHospitalizationFlg &&
      !hasSameStartEnd(this.mixinHospitalizationInfo.current)
    ) {
      this.medicalPayment.inHospitalFlg = 1
    }
    this.hospitalizationDetail = this.mixinMakeHospitalizationDetail()
    if (this.hospitalizationDetail.hospitalizationStartDate) {
      const { records } = this.hospitalizationDetail.hospitalization
      const latestMedicalPayment = this.medicalPaymentByOriginalId(
        records[records.length - 1].medicalPaymentOriginalId
      )
      if (!isStartEnd(latestMedicalPayment)) {
        const copied = [
          'diseaseClass1Id',
          'disease1Id',
          'diseaseClass2Id',
          'disease2Id',
          'onsetDate',
          'onsetAroundFlg'
        ]
        copied.forEach(key => {
          this.medicalPayment[key] = latestMedicalPayment[key]
        })
      }
    }

    // 保険状況の確認/保険適用処理に必要なデータを設定
    this.mixinSetInsurance()

    // 入力差分判定に必要なデータを設定
    this.mixinInputData = {
      medicalRecord: this.medicalRecord,
      medicalContent: this.medicalContent,
      examinationResultGroups: this.examinationResultGroups,
      medicalPayment: this.medicalPayment,
      medicalTreatmentItems: this.medicalTreatmentItems,
      resizedImageDiff: false
    }
    this.mixinSetInitialData()

    // 保険状況の確認 ※全データの設定が終了してから行うこと
    const insuranceWarning = this.getInsuranceWarning()
    if (insuranceWarning) {
      this.warningPopup.leftAlignMessage = insuranceWarning
      this.warningPopup.isOpened = true
    }
  },

  beforeDestroy() {
    this.$store.dispatch('examinationResults/resetNewExaminationResultGroups')
    this.$store.dispatch('uploadImages/resetResizedUploadImages')
    this.$store.dispatch(
      'medicalContentImages/resetResizedMedicalContentImages'
    )
  },

  methods: {
    calculateToFiveInMinutes(today) {
      const minutes = today.minutes()
      let usedMinutes = String(Math.floor(minutes / 5) * 5)
      if (usedMinutes.length === 1) {
        usedMinutes = '0' + usedMinutes
      }
      const startTime = today.format('HH') + usedMinutes
      const endTime = moment(startTime, 'HHmm')
        .add(this.reservationSetting.reservationTimeUnit, 'minutes')
        .format('HHmm')
      return { startTime, endTime }
    },
    handlePaymentNavigation() {
      this.medicalPayment.id
        ? this.mixinDiffFlg
          ? this.mixinConfirmMedicalPaymentRegister()
          : this.gotoPaymentPage()
        : this.mixinConfirmMedicalPaymentRegister()
    },
    gotoPaymentPage() {
      const ownerId = this.ownerId
      const patientId = this.patientId
      const medicalPaymentOriginalId = this.medicalPayment.originalId
      const patientPaymentsPath = `/main/karte/owners/${ownerId}/patients/${patientId}/payments/${medicalPaymentOriginalId}`
      this.mixinPaymentType === '未会計'
        ? this.$router.push(`${patientPaymentsPath}/new`)
        : this.$router.push(`${patientPaymentsPath}/show`)
    },
    unsavedResizedImage() {
      this.mixinInputData.resizedImageDiff = true
    },
    createMedicalContent() {
      this.mixinInputData.resizedImageDiff = false
      this.mixinCreateUpdateMedicalContent()
    },
    getInsuranceWarning() {
      // mixinSetInsurance で必要なデータが設定済みであることが前提
      let insuranceWarning = ''
      const hasInsurance = patient =>
        patient && ['anicom', 'ipet', 'docomo'].includes(patient.insurance)
      const { mixinPatient } = this
      if (hasInsurance(mixinPatient)) {
        const details = {
          anicom: this.mixinAnicomPatient,
          ipet: this.mixinIpetPatient,
          docomo: this.mixinIpetPatient
        }
        const { startDate, endDate } = details[mixinPatient.insurance] ?? {}
        if (endDate && endDate < this.medicalRecord.date) {
          const jps = {
            anicom: { insuranceName: 'アニコム', periodName: '窓口精算期間' },
            ipet: { insuranceName: 'アイペット', periodName: '保険期間' },
            docomo: { insuranceName: 'ドコモ', periodName: '保険期間' }
          }
          const { insuranceName, periodName } = jps[mixinPatient.insurance]
          const base = `診療日が患者に設定されている${periodName}を過ぎています。\n保険証の確認をお願いします。`
          const start = jpDate({ YYYYMMDD: startDate })
          const end = jpDate({ YYYYMMDD: endDate })
          const text = `保険:${insuranceName}, ${periodName}: ${start} ～ ${end}`
          insuranceWarning = `${base}\n\n${text}`
        }
      }
      return insuranceWarning
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-record-new {
  display: inline-block;
  min-width: 100%;
  > .windows {
    display: flex;
    position: relative;
    > .window {
      margin-left: 30px;
      max-height: 881px; // https://zpl.io/29Mogly での高さに準拠
      &.medical-payment-input-form {
        max-height: none;
      }
    }
    > .foot-print {
      width: 650px;
      height: 100%;
    }
  }
}
.slot-button {
  display: flex;
  justify-content: center;
  margin: 0px 20px 20px 20px;
}
</style>
