<template>
  <div class="measurement-result-list-table">
    <div class="table-header">
      <div
        class="cell"
        v-if="printFlg"
        :style="cellStyles.checkbox"
        data-test="cellForCheckBox"
      />
      <div class="cell" :style="cellStyles.datetime">検査日時</div>
      <div class="cell" :style="cellStyles.category">検査名</div>
      <div class="cell" :style="cellStyles.machine">検査機器</div>
    </div>
    <dynamic-scroller
      class="table-body"
      v-if="measurementResults && measurementResults.length > 0"
      :items="measurementResults"
      :min-item-size="minItemSize"
    >
      <template v-slot="{ item, index, active }">
        <dynamic-scroller-item
          :item="item"
          :active="active"
          :data-index="index"
        >
          <measurement-result-list-table-row
            :measurementResult="item"
            :index="index"
            :printFlg="printFlg"
            :checkboxStatus="
              checkboxStatuses.find(v => v.measurementResultId === item.id)
            "
            :disabledCheckBoxTitle="disabledCheckBoxTitle"
            :shouldDisableCheckBoxes="shouldDisableCheckBoxes"
            :cellStyles="cellStyles"
            @click-item="$emit('click-item', $event)"
            @check="$emit('check', $event)"
          >
          </measurement-result-list-table-row>
        </dynamic-scroller-item>
      </template>
    </dynamic-scroller>
    <div v-else data-test="noResult">対象のデータはありませんでした</div>
  </div>
</template>

<script>
import MeasurementResultListTableRow from '@/components/parts/molecules/MeasurementResultListTableRow'
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'

export default {
  name: 'MeasurementResultListTable',

  components: {
    MeasurementResultListTableRow,
    DynamicScroller,
    DynamicScrollerItem
  },

  props: {
    measurementResults: { type: Array, default: () => [] },
    printFlg: { type: Boolean, default: false },
    checkboxStatuses: { type: Array, default: () => [] },
    disabledCheckBoxTitle: { type: String, default: '' },
    shouldDisableCheckBoxes: { type: Boolean, default: false }
  },

  data() {
    return {
      minItemSize: 55 // minItemSizeを設定しないとエラーになるため設定。各アイテムの最小サイズの高さの設定。MeasurementResultListTableRow.vueのmin-height: 55px;の縦の最低幅を設定
    }
  },

  computed: {
    cellStyles() {
      return this.printFlg
        ? {
            checkbox: { width: '5%', minWidth: '40px', maxWidth: '40px' },
            datetime: { width: '20%', minWidth: '300px' },
            category: { width: '35%' },
            machine: { width: '40%' }
          }
        : {
            checkbox: {},
            datetime: { width: '20%', minWidth: '300px' },
            category: { width: '40%' },
            machine: { width: '40%' }
          }
    }
  }
}
</script>

<style lang="scss" scoped>
.measurement-result-list-table {
  font-size: 15px;
  > .table-header {
    box-sizing: border-box;
    display: flex;
    height: 40px;
    border: 1px solid #{$light-grey};
    background-color: #{$pale};
    padding-right: 17px; //table-body内の値と縦の位置を揃えるため、スクロールバーのwidth分スペースを空ける
    > .cell {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .table-body {
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;
  }
}
</style>
