<template>
  <div class="karte-setting" ref="karteSetting">
    <div class="wrap-outside">
      <div class="wrap-inside">
        <div class="fixed-display">
          <div class="setting-title" data-test="title">カルテ</div>
          <div class="setting-tab-title-bar">
            <base-setting-tab-title
              v-for="titleObj in tabTitles"
              :key="titleObj.title"
              :title="titleObj.title"
              :link="titleObj.link"
              :names="titleObj.names"
            />
          </div>
        </div>
        <div class="content-body">
          <router-view />
        </div>
      </div>
    </div>
    <announce-popup
      v-if="popup.flg"
      v-bind="popup"
      @close="popup.flg = false"
      >{{ popup.message }}</announce-popup
    >
    <unsaved-leave-popup :layerNumber="3" :popupType="navigationPopupType" />
  </div>
</template>
<script>
import BaseSettingTabTitle from '@/components/parts/atoms/BaseSettingTabTitle.vue'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import { mapGetters } from 'vuex'
import { CAN_OPEN_MEASUREMENT_RESULT_TAB_CLINIC_IDS } from '@/utils/define'

export default {
  name: 'KarteSetting',
  components: {
    BaseSettingTabTitle,
    AnnouncePopup,
    UnsavedLeavePopup
  },
  data() {
    return {
      popup: {
        flg: false,
        type: '',
        title: '',
        message: '',
        buttons: []
      }
    }
  },
  computed: {
    ...mapGetters({ clinic: 'clinic/getData' }),
    medicalRecordOriginalId() {
      return this.$store.getters['medicalRecords/getOriginalIdSetInTab']
    },
    productionMode() {
      return process.env.NODE_ENV === 'production'
    },
    tabTitles() {
      const tabs = [
        {
          title: '登録',
          link: this.medicalRecordOriginalId
            ? {
                name: 'medical-record-edit',
                params: { originalId: this.medicalRecordOriginalId }
              }
            : { name: 'medical-record-new' },
          names: ['medical-record-edit', 'medical-record-new']
        },
        {
          title: '診療内容',
          link: { name: 'patient-medical-contents' },
          names: ['patient-medical-contents']
        },
        {
          title: '診療明細',
          link: { name: 'patient-medical-payments' },
          names: ['patient-medical-payments']
        },
        {
          title: '予防接種',
          link: { name: 'patient-vaccinations' },
          names: ['patient-vaccinations']
        },
        {
          title: '検査',
          link: { name: 'medical-record-examinations' },
          names: ['medical-record-examinations']
        },
        {
          title: '画像',
          link: { name: 'patient-medical-record-images' },
          names: ['patient-medical-record-images']
        },
        {
          title: '見積書',
          link: { name: 'estimates' },
          names: ['estimates', 'estimate-new', 'estimate-edit']
        },
        {
          title: '会計',
          link: { name: 'patient-payments' },
          names: [
            'patient-payments',
            'patient-payment-show',
            'patient-payment-new'
          ]
        }
      ]
      if (
        !this.productionMode ||
        (this.productionMode &&
          CAN_OPEN_MEASUREMENT_RESULT_TAB_CLINIC_IDS.includes(this.clinic.id))
      ) {
        tabs.splice(5, 0, {
          title: '検査(連携)',
          link: { name: 'patient-measurement-results' },
          names: ['patient-measurement-results']
        })
      }
      return tabs
    },
    navigationPopupType() {
      return this.$route.name === 'patient-payment-show' ? 'print' : 'default'
    }
  },
  async created() {
    if (this.$route.name.includes('medical-record-edit')) {
      this.$store.dispatch(
        'medicalRecords/setOriginalIdSetInTab',
        Number(this.$route.params.originalId)
      )
    } else if (this.$route.name.includes('medical-record-new')) {
      this.$store.dispatch('medicalRecords/resetOriginalIdSetInTab')
    }
  }
}
</script>
<style lang="scss" scoped>
.karte-setting {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: auto;
  > .wrap-outside {
    > .wrap-inside {
      padding: 24px 48px;
      box-sizing: border-box;
      display: inline-block;
      min-width: 100%;
      > .fixed-display {
        position: sticky;
        position: -webkit-sticky;
        top: 24px;
        transform: translateY(-24px);
        padding-top: 24px;
        margin-bottom: -24px;
        z-index: 100;
        background-color: #{$white};
        > .setting-title {
          font-size: 20px;
          font-weight: bold;
        }
        > .setting-tab-title-bar {
          display: flex;
          flex-direction: row;
          height: 26px;
          margin-top: 20px;
          padding-bottom: -4px;
          border-bottom: 1px solid;
          border-color: #{$light-grey};
          box-sizing: border-box;
        }
      }
      > .content-body {
        margin-top: 30px;
      }
    }
  }
}
</style>
