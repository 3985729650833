<template>
  <div class="examination-list-table">
    <div class="table-header">
      <div class="cell date">登録日</div>
      <div class="cell examination-name">検査名</div>
      <div class="cell blood-flg">血液検査</div>
      <div class="cell species-name">表示種別</div>
      <div class="cell buttons"></div>
    </div>
    <div class="table-body" v-if="displayData.length > 0">
      <div
        class="row"
        v-for="(resultGroup, index) in displayData"
        :key="`examination-${index}`"
      >
        <div class="upper">
          <div
            class="image-allow"
            data-test="arrow"
            @click="toggleShowFlg(resultGroup)"
          >
            <img
              src="@/assets/images/allow3.png"
              class="bottom-allow"
              v-show="resultGroup.showFlg"
            />
            <img
              src="@/assets/images/allow4.png"
              class="right-allow"
              v-show="!resultGroup.showFlg"
            />
          </div>
          <div class="area date" data-test="upper-cell">
            {{ formatDate(resultGroup.data[0][0].date) }}
          </div>
          <div class="area examination-name" data-test="upper-cell">
            {{ resultGroup.data[0][0].treatmentName }}
          </div>
          <div class="area blood-flg" data-test="upper-cell">
            {{ toCircleOrCrossMethod(resultGroup.data[0][0].bloodFlg) }}
          </div>
          <div class="area species-name" data-test="upper-cell">
            {{ resultGroup.data[0][0].speciesName }}
          </div>
          <div class="area buttons">
            <slot name="buttons" :resultGroup="resultGroup.data"></slot>
          </div>
        </div>
        <div class="lower" v-show="resultGroup.showFlg">
          <div class="item-table">
            <div class="item-table-header">
              <div class="cell">項目区分名</div>
              <div class="cell">項目名</div>
              <div class="cell">結果</div>
              <div class="cell">単位</div>
              <div class="cell">基準値</div>
              <div class="cell">コメント</div>
            </div>
            <div
              class="item-table-body"
              v-for="(topicClassGroup, index) in makeFilteredResultGroupData(
                resultGroup.data
              )"
              :key="`topic-class-${index}`"
            >
              <div class="left">
                <div class="cell" data-test="lower-left-cell">
                  <div class="text">
                    {{ topicClassGroup[0].topicClassName }}
                  </div>
                </div>
              </div>
              <div class="right">
                <div
                  class="row"
                  v-for="(topic, index) in topicClassGroup"
                  :key="`topic-${index}`"
                >
                  <div class="cell" data-test="lower-right-cell">
                    <div class="text">{{ topic.topicName }}</div>
                  </div>
                  <div class="cell" data-test="lower-right-cell">
                    <div class="text">{{ topic.result }}</div>
                  </div>
                  <div class="cell" data-test="lower-right-cell">
                    <div class="text">{{ topic.unit }}</div>
                  </div>
                  <div class="cell" data-test="lower-right-cell">
                    <div class="text">{{ topic.reference }}</div>
                  </div>
                  <div class="cell" data-test="lower-right-cell">
                    <div class="text">{{ topic.comment }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>対象のデータはありませんでした</div>
  </div>
</template>

<script>
import { toCircleOrCross } from '@/utils/convert_string'
import { makeFilteredResultGroupData } from '@/utils/examination_utils'
import _ from 'lodash'
import moment from 'moment'

const topicItemStyles = [
  { textAlign: 'center' },
  { textAlign: 'center' },
  { textAlign: 'center' },
  { textAlign: 'center' },
  { textAlign: 'center' }
]

export default {
  name: 'ExaminationListTable',

  components: {},

  props: {
    displayResults: { type: Array }
  },

  data() {
    return {
      displayData: [],
      panelShowFlg: true,
      topicItemData: {
        keys: [
          'topicClassName',
          'topicName',
          'resultUnit',
          'reference',
          'comment'
        ],
        headers: ['項目区分名', '項目名', '結果（単位）', '基準値', 'コメント'],
        headerCellStyles: topicItemStyles,
        bodyCellStyles: topicItemStyles,
        bodyMaxHeight: '120px'
      }
    }
  },

  watch: {
    displayResults: function() {
      this.displayData = _.cloneDeep(this.displayResults)
    }
  },

  created() {
    this.displayData = _.cloneDeep(this.displayResults)
  },

  methods: {
    makeFilteredResultGroupData(data) {
      return makeFilteredResultGroupData(data)
    },
    toggleShowFlg(resultGroup) {
      resultGroup.showFlg = !resultGroup.showFlg
    },
    toCircleOrCrossMethod(val) {
      return toCircleOrCross(val)
    },
    formatDate(date) {
      return moment(date, 'YYYYMMDD').format('YYYY年MM月DD日（dd）')
    }
  }
}
</script>

<style lang="scss" scoped>
$border-color: #{$light-grey};
.examination-list-table {
  width: 100%;
  > .table-header {
    display: flex;
    height: 40px;
    border: 1px solid #{$light-grey};
    font-size: 15px;
    background-color: #{$pale};
    padding-right: 17px;
    > .cell {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      &.date {
        width: 20%;
      }
      &.examination-name {
        width: 20%;
      }
      &.blood-flg {
        width: 20%;
      }
      &.species-name {
        width: 20%;
      }
      &.buttons {
        width: 20%;
      }
    }
    > .cell ~ .cell {
      border-left: 1px solid #{$skin};
    }
  }
  > .table-body {
    box-sizing: border-box;
    width: 100%;
    overflow-y: scroll;
    font-size: 15px;
    border: solid #{$light-grey};
    border-width: 0px 1px 1px 1px;
    > .row {
      width: 100%;
      &:nth-child(even) {
        background-color: #{$white_f7};
      }
      > .upper {
        width: 100%;
        min-height: 55px;
        display: flex;
        align-items: center;
        position: relative;
        > .image-allow {
          position: absolute;
          margin-left: 18px;
          width: 16px;
          cursor: pointer;
          > .bottom-allow {
            margin-top: 5px;
            width: 15px;
            height: 9px;
          }
          > .right-allow {
            margin-top: 5px;
            width: 9px;
            height: 15px;
          }
        }
        > .area {
          display: flex;
          justify-content: center;
          word-break: break-all;
          padding: 5px 8px;
          &.date {
            width: 20%;
          }
          &.examination-name {
            width: 20%;
          }
          &.blood-flg {
            width: 20%;
          }
          &.species-name {
            width: 20%;
          }
          &.buttons {
            width: 20%;
            justify-content: flex-end;
          }
        }
      }
      > .lower {
        display: flex;
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        > .item-table {
          width: 100%;
          font-size: 13px;
          text-align: center;
          > .item-table-header {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            height: 30px;
            background-color: #{$gray_f0};
            border: solid 1px $border-color;
            border-width: 1px 1px 1px 1px;
            > .cell {
              width: 100%;
              line-height: 29px;
              padding: 0 8px;
              font-weight: bold;
              &:first-child {
                border-color: #{$gray_f0};
              }
            }
          }
          > .item-table-body {
            box-sizing: border-box;
            width: 100%;
            display: flex;
            flex-direction: row;
            background-color: #{$white};
            border: solid $border-color;
            border-width: 0px 1px 1px 1px;
            > .left {
              width: 16.66%;
              margin: auto;
              > .cell {
                border: unset;
                padding: 5px 8px;
                > .text {
                  white-space: pre-wrap;
                  word-break: break-all;
                }
              }
            }
            > .right {
              width: 83.44%;
              display: flex;
              flex-direction: column;
              > .row {
                width: 100%;
                display: flex;
                flex-direction: row;
                border-bottom: solid 1px $border-color;
                &:last-child {
                  border: unset;
                }
                > .cell {
                  width: 25%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 5px 8px;
                  > .text {
                    white-space: pre-wrap;
                    word-break: break-all;
                  }
                }
              }
            }
          }
        }
      }
    }
    > .row ~ .row {
      border-top: 1px solid #{$light-grey};
    }
    .cell {
      border-left: 1px solid $border-color;
    }
  }
}
</style>
