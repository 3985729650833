<template>
  <div class="medical-payments-history-detail">
    <div
      v-if="medicalPayment.delFlg"
      class="overlay"
      :class="historyStatus"
      data-test="overlay"
    />
    <div class="date-author" data-test="date-author">
      <div class="item author">
        <div class="label">作成者</div>
        <div class="value" :title="authorNameTime" data-test="author-name-time">
          {{ authorNameTime }}
        </div>
      </div>
      <div class="item editor" v-if="showEditor">
        <div class="label">
          {{ historyStatus === 'delete' ? '削除者' : '編集者' }}
        </div>
        <div class="value" :title="editorNameTime" data-test="editor-name-time">
          {{ editorNameTime }}
        </div>
      </div>
    </div>
    <div class="date-staff">
      <div class="item date">
        <div class="label">診療日</div>
        <div class="value">{{ displayDate }}</div>
      </div>
      <div class="item staff">
        <div class="label">担当者</div>
        <div class="value" :title="staffName" data-test="staff-name">
          {{ staffName }}
        </div>
      </div>
    </div>
    <div class="diseases">
      <div class="item disease1">
        <div class="label">診断1</div>
        <div class="value">
          <div class="disease-class">
            <div class="lower-label">疾患名</div>
            <div
              class="lower-value"
              :title="toItemName(medicalPayment.diseaseClass1)"
              data-test="disease-class1-name"
            >
              {{ toItemName(medicalPayment.diseaseClass1) }}
            </div>
          </div>
          <div class="disease">
            <div class="lower-label">症状名</div>
            <div
              class="lower-value"
              :title="toItemName(medicalPayment.disease1)"
              data-test="disease1-name"
            >
              {{ toItemName(medicalPayment.disease1) }}
            </div>
          </div>
        </div>
      </div>
      <div class="item disease2">
        <div class="label">診断2</div>
        <div class="value">
          <div class="disease-class">
            <div class="lower-label">疾患名</div>
            <div
              class="lower-value"
              :title="toItemName(medicalPayment.diseaseClass2)"
              data-test="disease-class2-name"
            >
              {{ toItemName(medicalPayment.diseaseClass2) }}
            </div>
          </div>
          <div class="disease">
            <div class="lower-label">症状名</div>
            <div
              class="lower-value"
              :title="toItemName(medicalPayment.disease2)"
              data-test="disease2-name"
            >
              {{ toItemName(medicalPayment.disease2) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="insurance-apply">
      <div class="apply" data-test="apply">
        <div class="label">保険適用</div>
        <div class="value">
          {{ medicalPayment.applyFlg === 1 ? '有り' : '無し' }}
        </div>
      </div>
      <div v-if="insuranceDetail" class="detail" data-test="detail">
        <div class="part jp-insurance">
          <div class="label">保険</div>
          <div class="value">{{ insuranceDetail.jpInsurance }}</div>
        </div>
        <div class="part policy-number">
          <div class="label">証券番号</div>
          <div class="value">{{ insuranceDetail.policyNumber }}</div>
        </div>
        <div
          v-if="anicomCIdCheck"
          class="part authorization-number"
          data-test="authorization-number"
        >
          <div class="label">承認番号</div>
          <div class="value">{{ insuranceDetail.authorizationNumber }}</div>
        </div>
      </div>
    </div>
    <div class="surgery-hospitalization">
      <div class="surgery">
        <div class="label">手術</div>
        <div class="value">
          {{ medicalPayment.surgeryFlg === 1 ? '有り' : '無し' }}
        </div>
      </div>
      <div class="hospitalization">
        <div class="value" data-test="hospitalization-status">
          {{ hospitalizationStatus }}
        </div>
      </div>
    </div>
    <div class="medical-treatment-items">
      <data-table v-bind="treatmentItemData" :items="displayTreatmentItems" />
    </div>
    <div class="price-table">
      <data-table
        v-bind="priceDetailData"
        :items="displayPriceDetails"
        :disabled="isInHospital"
      />
    </div>
    <div class="bill">
      <div class="item insurance" :class="{ disabled: isInHospital }">
        <div class="label">保険請求額</div>
        <div class="value" data-test="insurance-price">
          {{ dispPrice(medicalPayment.insurancePrice) }}
        </div>
      </div>
      <div class="item owner" :class="{ disabled: isInHospital }">
        <div class="label">
          飼主請求額
        </div>
        <div class="value" data-test="burden-amount">
          {{ dispPrice(medicalPayment.burdenAmount) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '@/components/parts/organisms/DataTable'
import { mapGetters } from 'vuex'
import moment from 'moment'

const treatmentItemStyles = [
  { width: '30%', textAlign: 'left', padding: '0 6px' },
  { width: '6%' },
  { width: '14%' },
  { width: '10%' },
  { width: '10%' },
  { width: '15%' },
  { width: '15%' }
].map(v => {
  return { ...v, fontSize: '11px' }
})
const priceDetailStyles = [...Array(5).keys()].map(v => {
  return { ...v, fontSize: '11px' }
})

export default {
  name: 'MedicalPaymentsHistoryDetail',

  components: {
    DataTable
  },

  props: {
    medicalPayment: { type: Object }
  },

  data() {
    return {
      treatmentItemData: {
        keys: [
          'name',
          'insuranceFlg',
          'unitPrice',
          'amount',
          'discountRate',
          'discountPrice',
          'subtotal'
        ],
        headers: [
          '診療項目内容',
          '保険',
          '単価（￥）',
          '数量',
          '割引（%）',
          '値引（￥）',
          '小計（￥）'
        ],
        headerCellStyles: treatmentItemStyles,
        bodyCellStyles: treatmentItemStyles.map((v, i) => {
          return { ...v, textAlign: i === 1 ? 'center' : v.textAlign }
        }),
        bodyStyle: { maxHeight: '300px' },
        scrollFlg: true,
        stripeFlg: true
      },
      priceDetailData: {
        keys: [
          'subtotal',
          'rateDiscountedPrice',
          'fixedDiscountedPrice',
          'taxIncludedPrice',
          'bill'
        ],
        headers: [
          '診療費 小計',
          '割引適用額',
          '値引適用額',
          '消費税',
          '請求額'
        ],
        headerCellStyles: priceDetailStyles,
        bodyCellStyles: priceDetailStyles,
        stripeFlg: true
      }
    }
  },

  computed: {
    ...mapGetters({
      getStaff: 'staffs/getDataById'
    }),
    displayDate() {
      const date = this.medicalPayment.date
      return moment(date, 'YYYYMMDD').format('YYYY年MM月DD日（dd）')
    },
    displayTreatmentItems() {
      return this.medicalPayment.medicalTreatmentItems.map(v => {
        return {
          ...v,
          insuranceFlg: v.insuranceFlg === 1 ? '◯' : '✕',
          unitPrice: `￥${v.unitPrice.toLocaleString()}`,
          discountRate: `${v.discountRate.toLocaleString()} %`,
          discountPrice: `￥${v.discountPrice.toLocaleString()}`,
          subtotal: `￥${v.subtotal.toLocaleString()}`
        }
      })
    },
    displayPriceDetails() {
      const subtotal = this.medicalPayment.subtotal
      let rateDiscountedPrice, fixedDiscountedPrice
      if (this.medicalPayment.insuranceType === 'ipet-docomo') {
        rateDiscountedPrice = ''
        fixedDiscountedPrice = subtotal - this.medicalPayment.discountIpet
      } else {
        // insuranceType === 'anicom' or insuranceType === ''
        rateDiscountedPrice = Math.trunc(
          (subtotal * (100 - this.medicalPayment.discountRate)) / 100
        )
        fixedDiscountedPrice =
          rateDiscountedPrice - this.medicalPayment.discountPrice
      }
      return this.isInHospital
        ? [
            {
              subtotal: '　', // 全角スペースが無いとテーブルが潰れる
              rateDiscountedPrice: '',
              fixedDiscountedPrice: '',
              taxIncludedPrice: '',
              bill: ''
            },
            {
              subtotal: '￥' + subtotal.toLocaleString(),
              rateDiscountedPrice: '',
              fixedDiscountedPrice: '',
              taxIncludedPrice: '',
              bill: ''
            }
          ]
        : [
            {
              subtotal: '',
              rateDiscountedPrice: `割引率 ${this.medicalPayment.discountRate} %`,
              fixedDiscountedPrice: `値引額 ￥${this.medicalPayment.discountPrice.toLocaleString()}`,
              taxIncludedPrice:
                '￥' + this.medicalPayment.consumptionTax.toLocaleString(),
              bill: ''
            },
            {
              subtotal: '￥' + subtotal.toLocaleString(),
              rateDiscountedPrice:
                rateDiscountedPrice === ''
                  ? ''
                  : '￥' + rateDiscountedPrice.toLocaleString(),
              fixedDiscountedPrice:
                '￥' + fixedDiscountedPrice.toLocaleString(),
              taxIncludedPrice:
                '￥' + this.medicalPayment.taxIncludedPrice.toLocaleString(),
              bill: '￥' + this.medicalPayment.taxIncludedPrice.toLocaleString()
            }
          ]
    },
    historyStatus() {
      return this.medicalPayment.latest.id !== this.medicalPayment.id
        ? 'edit'
        : this.medicalPayment.latest.delFlg === 1
        ? 'delete'
        : ''
    },
    showEditor() {
      return (
        this.medicalPayment.id !== this.medicalPayment.original.id ||
        this.historyStatus === 'delete'
      )
    },
    authorNameTime() {
      return `${this.makeStaffName(
        this.medicalPayment.original.inputStaffId
      )}（${this.formatDateTime(this.medicalPayment.original.createdAt)}）`
    },
    editorNameTime() {
      return this.historyStatus === 'delete'
        ? `${this.makeStaffName(
            this.medicalPayment.deleteStaffId
          )}（${this.formatDateTime(this.medicalPayment.updatedAt)}）`
        : `${this.makeStaffName(
            this.medicalPayment.inputStaffId
          )}（${this.formatDateTime(this.medicalPayment.createdAt)}）`
    },
    staffName() {
      return this.makeStaffName(this.medicalPayment.staffId)
    },
    insuranceDetail() {
      let insuranceDetail = null
      if (this.medicalPayment.applyFlg === 1) {
        let jpInsurance = ''
        let policyNumber = ''
        let authorizationNumber = ''
        if (this.anicomCIdCheck) {
          jpInsurance = 'アニコム'
          policyNumber = this.anicomCIdCheck.patientCId
          authorizationNumber = this.anicomCIdCheck.code
        } else if (this.ipetCheck) {
          const { insurance } = this.ipetCheck
          if (insurance === 'ipet') jpInsurance = 'アイペット'
          else if (insurance === 'docomo') jpInsurance = 'ドコモ'
          policyNumber = this.ipetCheck.policyNo
        }
        insuranceDetail = { jpInsurance, policyNumber, authorizationNumber }
      }
      return insuranceDetail
    },
    anicomCIdCheck() {
      return this.$store.getters['anicomCIdChecks/getDataById'](
        this.medicalPayment.anicomCIdCheckId
      )
    },
    ipetCheck() {
      return this.$store.getters['ipetChecks/getDataById'](
        this.medicalPayment.ipetCheckId
      )
    },
    hospitalizationStatus() {
      return this.medicalPayment.startHospitalizationFlg === 1 &&
        this.medicalPayment.endHospitalizationFlg === 1
        ? '入院開始 - 退院'
        : this.medicalPayment.startHospitalizationFlg === 1 &&
          this.medicalPayment.middleCalculateFlg === 1
        ? '入院開始 / 途中精算'
        : this.medicalPayment.startHospitalizationFlg === 1
        ? '入院開始'
        : this.medicalPayment.inHospitalFlg === 1 &&
          this.medicalPayment.middleCalculateFlg === 1
        ? '入院中 / 途中精算'
        : this.medicalPayment.inHospitalFlg === 1
        ? '入院中'
        : this.medicalPayment.endHospitalizationFlg === 1
        ? '退院'
        : ''
    },
    isInHospital() {
      return (
        this.hospitalizationStatus === '入院開始' ||
        this.hospitalizationStatus === '入院中'
      )
    },
    dispPrice() {
      return price => {
        return this.isInHospital ? '' : '￥' + price.toLocaleString()
      }
    }
  },

  methods: {
    toItemName(item) {
      const name = item ? item.name : ''
      return name
    },
    formatDateTime(dateTime) {
      return moment(dateTime).format('YYYY年MM月DD日 HH:mm')
    },
    makeStaffName(staffId) {
      const staff = this.getStaff(staffId)
      const staffName = staff ? staff.lastName + ' ' + staff.firstName : ''
      return staffName
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-payments-history-detail {
  box-sizing: border-box;
  position: relative;
  padding: 10px;
  background-color: #{$white};
  border: solid 1px #{$light-grey};
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  > .overlay {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    pointer-events: none;
    &.edit {
      background: repeating-linear-gradient(
        135deg,
        #{$brown-gray},
        #{$brown-gray} 10px,
        transparent 10px,
        transparent 20px
      );
      opacity: 0.3;
    }
    &.delete {
      background-color: #{$brown-gray};
      opacity: 0.3;
    }
  }
  > .date-author {
    display: flex;
    > .item {
      display: flex;
      align-items: center;
      flex: 1;
      width: 0;
      > .label {
        width: 45px;
        font-size: 11px;
      }
      > .value {
        flex: 1;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    > .editor {
      padding-left: 10px;
      font-weight: bold;
    }
  }
  > .date-staff {
    margin-top: 5px;
    display: flex;
    height: 33px;
    > .item {
      display: flex;
      align-items: center;
      flex: 1;
      width: 0;
      > .label {
        width: 45px;
        font-size: 11px;
      }
      > .value {
        flex: 1;
        font-size: 13px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    > .staff {
      padding-left: 10px;
    }
  }
  > .diseases {
    height: 66px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    > .item {
      flex: 1;
      display: flex;
      align-items: center;
      > .label {
        width: 45px;
        font-size: 11px;
      }
      > .value {
        box-sizing: border-box;
        width: 539px;
        display: flex;
        align-items: center;
        font-size: 13px;
        > .disease-class {
          width: 206px;
        }
        > .disease {
          flex: 1;
          padding-left: 10px;
        }
        > .disease-class,
        .disease {
          display: flex;
          > .lower-label {
            width: 50px;
          }
          > .lower-value {
            flex: 1;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
  > .insurance-apply {
    margin-top: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0 30px;
    width: 100%;
    height: 57px;
    border: solid #{$light-grey};
    border-width: 1px 0;
    font-size: 13px;
    > .apply {
      display: flex;
      align-items: center;
      gap: 0 15px;
      > .label {
        font-size: 11px;
      }
    }
    > .detail {
      display: flex;
      align-items: center;
      gap: 0 25px;
      > .part {
        > .label {
          height: 20px;
        }
        > .value {
          height: 20px;
        }
      }
    }
  }
  > .surgery-hospitalization {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 13px;
    > .surgery {
      display: flex;
      align-items: center;
      flex: 1;
      > .label {
        margin-right: 15px;
        font-size: 11px;
      }
    }
    > .hospitalization {
      display: flex;
      flex: 1;
      font-weight: bold;
    }
  }
  > .medical-treatment-items {
    margin-top: 10px;
    width: 100%;
  }
  > .price-table {
    margin-top: 10px;
    width: 100%;
  }
  > .bill {
    margin-top: 15px;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    height: 40px;
    border: 1px solid #{$light-grey};
    > .item {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      > .label {
        font-size: 13px;
        font-weight: bold;
      }
      > .value {
        font-size: 15px;
      }
    }
    > .disabled {
      background-color: #{$gray_fc};
    }
    > .item ~ .item {
      border-left: 1px solid #{$light-grey};
    }
  }
}
</style>
