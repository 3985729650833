<template>
  <div class="base-button-round">
    <button
      type="button"
      @click="handleClick"
      :disabled="disabled"
      :style="styles"
      data-e2e="base-button-round"
    >
      <span :style="textStyles"><slot /> ></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'BaseButtonRound',

  props: {
    disabled: { type: Boolean, default: false },
    styles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    },
    textStyles: {
      type: Object,
      validator: function(obj) {
        return Object.values(obj).every(v => typeof v === 'string')
      }
    }
  },

  data() {
    return {
      id: null
    }
  },

  beforeDestroy() {
    clearTimeout(this.id)
  },

  methods: {
    handleClick(e) {
      this.$emit('click', e)
    }
  }
}
</script>

<style lang="scss" scoped>
.base-button-round {
  > button {
    width: 90px;
    height: 30px;
    border-radius: 30px;
    border: solid 1px #{$pumpkin};
    background-color: #fff;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      margin: 2px auto 0 auto;
      height: 29px;
    }
    > span {
      height: 22px;
      font-size: 15px;
      font-weight: bold;
      line-height: 1.47;
      text-align: left;
      color: #{$pumpkin};
      display: inline-block;
      vertical-align: middle;
    }
  }
  > button:disabled {
    background-color: #{$light-grey};
    border: solid 2px #{$light-grey};
    cursor: default;
    > span {
      color: #{$greyish-brown};
    }
    &:hover {
      opacity: 1;
    }
    &:active {
      margin: 0 auto 0 auto;
      height: 33px;
    }
  }
}
@media (max-width: $tablet-width) {
  .base-button-small-orange {
    > button {
      > span {
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
}
</style>
