<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div
      class="unlinked-measurement-results-popup"
      @keyup.esc.stop="closePopup"
      tabindex="-1"
    >
      <base-loading :waitFlg="waitFlg" />
      <popup
        :buttons="['閉じる']"
        :contentStyle="{}"
        @close="closePopup"
        @click-close-mark="closePopup"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">検査機器連携</div>
            <div class="search">
              <search-area
                :textBoxLabel="'検索単語'"
                :textValue="searchWord"
                :placeholder="'検査機器'"
                :periodFlg="true"
                :periodLabel="'期間'"
                @input-start-date="inputDate($event, 'start')"
                @input-end-date="inputDate($event, 'end')"
                @clear="clearDate"
                v-model="searchWord"
              />
            </div>
            <div class="list">
              <measurement-result-list-table
                :measurementResults="filteredMeasurementResults"
                @click-item="openMeasurementResultPopup"
              />
            </div>
          </div>
        </template>
      </popup>
      <announce-popup
        v-if="announcePopup.isOpened"
        v-bind="announcePopup"
        :layer-number="3"
        @close="closeAnnouncePopup"
        >{{ announcePopup.message }}</announce-popup
      >
      <measurement-result-popup
        v-if="showMeasurementResultPopup"
        :buttons="lookOnlyFlg ? ['閉じる'] : ['閉じる', '関連付け']"
        :can-delete="true"
        :disabled-flg="waitFlg"
        :layer-number="2"
        :measurement-result-id="selectedMeasurementResultId"
        :show-reference-range="false"
        @close="closeMeasurementResultPopup"
        @decide="updatePatientId"
      />
    </div>
  </focus-trap>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import MeasurementResultListTable from '@/components/parts/organisms/MeasurementResultListTable'
import MeasurementResultPopup from '@/components/popups/MeasurementResultPopup'
import Popup from '@/components/popups/Popup'
import SearchArea from '@/components/parts/molecules/SearchArea'
import { FocusTrap } from 'focus-trap-vue'
import { compareByKeys } from '@/utils/compare'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'UnlinkedMeasurementResultsPopup',

  components: {
    AnnouncePopup,
    BaseLoading,
    FocusTrap,
    MeasurementResultListTable,
    MeasurementResultPopup,
    Popup,
    SearchArea
  },

  props: {
    patientId: { type: Number }
  },

  data() {
    return {
      announcePopup: {
        isOpened: false,
        type: 'failure',
        title: '失敗',
        message: '',
        buttons: ['閉じる']
      },
      searchWord: '',
      selectedMeasurementResultId: 0,
      showMeasurementResultPopup: false,
      startDate: '',
      endDate: '',
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getMasterLaboratoryMachineIndexedByCode:
        'master/getMasterLaboratoryMachineIndexedByCode',
      getMeasurementResultsByPatientId: 'measurementResults/getDataByPatientId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    unlinkedMeasurementResults() {
      return this.getMeasurementResultsByPatientId(0) || []
    },
    filteredMeasurementResults() {
      return this.unlinkedMeasurementResults
        .filter(v => this.filterByDate(v) && this.filterByWord(v))
        .sort((a, b) => compareByKeys(a, b, ['date', 'time', 'id']))
    }
  },

  methods: {
    async updatePatientId() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'measurementResults/updatePatientId',
        {
          id: this.selectedMeasurementResultId,
          newPatientId: this.patientId
        }
      )
      if (result === 'success') {
        this.closeMeasurementResultPopup()
      } else {
        if (
          result === 'no measurementResult' ||
          result === 'no data in clinic'
        ) {
          this.announcePopup.message =
            '患者との関連付けに失敗しました。\n選択した項目は既に削除されています\n他の項目を選択して下さい。'
          this.closeMeasurementResultPopup()
        } else {
          this.announcePopup.message = '患者との関連付けに失敗しました'
        }
        this.announcePopup.isOpened = true
      }
      this.waitFlg = false
    },
    openMeasurementResultPopup(measurementResult) {
      this.selectedMeasurementResultId = measurementResult.id
      this.showMeasurementResultPopup = true
    },
    closeMeasurementResultPopup() {
      this.showMeasurementResultPopup = false
      this.selectedMeasurementResultId = 0
    },
    closeAnnouncePopup() {
      this.announcePopup.isOpened = false
    },
    filterByDate(measurementResult) {
      const date = moment(measurementResult.date, 'YYYYMMDD').format('YYYYMMDD')
      if (this.startDate !== '' && this.endDate !== '') {
        return this.startDate <= date && date <= this.endDate
      } else if (this.startDate !== '') {
        return this.startDate <= date
      } else if (this.endDate !== '') {
        return date <= this.endDate
      } else {
        return true
      }
    },
    filterByWord(measurementResult) {
      const searchWord = this.searchWord.toLowerCase().trim()
      const deviceName = this.getMasterLaboratoryMachineIndexedByCode(
        measurementResult.masterLaboratoryMachineCode
      ).name
      return deviceName.toLowerCase().includes(searchWord)
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    closePopup() {
      if (!this.waitFlg) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.unlinked-measurement-results-popup {
  position: absolute;
  top: 0;
  left: 0;
  .content {
    box-sizing: border-box;
    > .title {
      font-size: 16px;
      font-weight: bold;
      text-align: left;
    }
    > .search {
      margin-top: 15px;
      min-width: 900px;
      padding-right: 25px;
    }
    > .list {
      margin-top: 25px;
      height: 481px;
      min-width: 900px;
      padding-right: 25px;
      ::v-deep .table-body {
        max-height: 441px;
      }
    }
  }
}
</style>
