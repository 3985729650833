<template>
  <div
    class="measurement-result-list-table-row"
    :class="{
      first: index === 0,
      even: index % 2 !== 0,
      print: printFlg
    }"
    @click="clickItem"
  >
    <div
      class="cell"
      v-if="printFlg && checkboxStatus"
      :title="
        !checkboxStatus.isChecked && shouldDisableCheckBoxes
          ? disabledCheckBoxTitle
          : ''
      "
      :style="cellStyles.checkbox"
      data-test="cellForCheckBox"
    >
      <base-check-box
        :isChecked="checkboxStatus.isChecked"
        :disabled="!checkboxStatus.isChecked && shouldDisableCheckBoxes"
        @input="check"
      />
    </div>
    <div class="cell" :style="cellStyles.datetime">{{ formattedDateTime }}</div>
    <div
      class="cell category"
      :style="cellStyles.category"
      :title="categoryName"
    >
      <span>{{ categoryName }}</span>
    </div>
    <div class="cell" :style="cellStyles.machine" :title="machineName">
      <span>{{ machineName }}</span>
    </div>
  </div>
</template>

<script>
import BaseCheckBox from '@/components/parts/atoms/BaseCheckBox'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'MeasurementResultListTableRow',

  components: { BaseCheckBox },

  props: {
    measurementResult: { type: Object, default: () => {} },
    index: { type: Number },
    printFlg: { type: Boolean, default: false },
    checkboxStatus: { type: Object, default: () => {} },
    disabledCheckBoxTitle: { type: String, default: '' },
    shouldDisableCheckBoxes: { type: Boolean, default: false },
    cellStyles: { type: Object, default: () => {} }
  },

  computed: {
    ...mapGetters({
      getMasterLaboratoryCategoryIndexedByCode:
        'master/getMasterLaboratoryCategoryIndexedByCode',
      getMasterLaboratoryMachineIndexedByCode:
        'master/getMasterLaboratoryMachineIndexedByCode'
    }),
    formattedDateTime() {
      if (this.measurementResult.time === '') {
        return moment(this.measurementResult.date, 'YYYYMMDD').format(
          'Y年M月D日（dd）'
        )
      } else {
        const datetime =
          this.measurementResult.date + this.measurementResult.time
        return moment(datetime, 'YYYYMMDDHHmm').format('Y年M月D日（dd）H時m分')
      }
    },
    masterLaboratoryMachine() {
      return this.getMasterLaboratoryMachineIndexedByCode(
        this.measurementResult.masterLaboratoryMachineCode
      )
    },
    machineName() {
      return this.masterLaboratoryMachine.name
    },
    categoryName() {
      return this.getMasterLaboratoryCategoryIndexedByCode(
        this.masterLaboratoryMachine.masterLaboratoryCategoryCode
      ).name
    }
  },

  methods: {
    clickItem() {
      if (this.printFlg) return
      this.$emit('click-item', this.measurementResult)
    },
    check(isChecked) {
      this.$emit('check', {
        isChecked,
        measurementResultId: this.measurementResult.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.measurement-result-list-table-row {
  min-height: 55px; // この値を変える場合はMeasurementResultListTable.vueのminItemSize: 55 の値も変更する
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  border-top: 1px solid #{$light-grey};
  &.first {
    border-top: none;
  }
  &.even {
    background-color: #{$white_f7};
  }
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &.print {
    &:hover {
      opacity: 1;
      cursor: auto;
    }
  }
  > .cell {
    box-sizing: border-box;
    padding: 0 10px;
    &.category {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}
</style>
