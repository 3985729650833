import * as self from '@/utils/print_measurement_results'
import { makeClinicText, makeFooter, makePageSize } from '@/utils/print_utils'
import { createPdf } from 'pdfmake/build/pdfmake.min.js'
import '@/utils/vfs_fonts' // この行がないとテストが通らない
import { makeOwnerPatientText } from './print_utils'
import moment from 'moment'

export const toNum = (value, referenceRange) => {
  // value と referenceRange を数値に変換
  const numValue = parseFloat(value)
  const numReferenceRange = referenceRange.split('-').map(parseFloat)
  if (numReferenceRange.length === 2) {
    const [min, max] = numReferenceRange
    return { numValue, min, max }
  } else {
    return { numValue, min: NaN, max: NaN }
  }
}

export const isLessThanReferenceRangeMin = (numValue, min, equalSign) => {
  if (isNaN(numValue) || isNaN(min)) return false
  return numValue < min && equalSign !== '>'
}

export const isMoreThanReferenceRangeMax = (numValue, max, equalSign) => {
  if (isNaN(numValue) || isNaN(max)) return false
  return numValue > max && equalSign !== '<'
}

export const makeValueColumn = (equalSign, value, referenceRange) => {
  const { numValue, min, max } = self.toNum(value, referenceRange)
  const text = `${equalSign.replace('=', '')} ${value}`.trim()
  if (self.isLessThanReferenceRangeMin(numValue, min, equalSign)) {
    return { text: text + ' ↓', color: '#0000B0' }
  } else if (self.isMoreThanReferenceRangeMax(numValue, max, equalSign)) {
    return { text: text + ' ↑', color: '#B00000' }
  } else {
    return { text }
  }
}

export const makeDocDefinition = ({
  clinic,
  clinicImage,
  showClinicName,
  owner,
  patient,
  speciesName,
  measurementResultsToPrint
}) => {
  const paperSize = 'A4'
  const ratio = 1

  //タイトル、飼主・患者情報、医院情報
  const ownerPatientText = makeOwnerPatientText({
    owner,
    ownerShowIdFlg: true,
    patient,
    patientShowIdFlg: true,
    speciesName
  })
  const clinicText = makeClinicText({
    ratio,
    clinic,
    clinicImage,
    showClinicName
  })
  const contentHeader = {
    columns: [
      {
        stack: [
          {
            //右端の医院情報のどれかの文字数が長い時に「検査結果」の文字が改行されないようwidthを設定。
            //widthはcolumnsと組み合わせて使用しないと反映されないようなので下記のような書き方をしている。
            columns: [{ text: '検査結果', fontSize: 40, width: 260 }]
          },
          ownerPatientText
        ],
        width: '*'
      },
      { stack: clinicText, width: 'auto', margin: [10, 0, 0, 0] }
    ],
    margin: [0, 0, 0, 5]
  }

  //検査結果テーブル
  const widthsOfEachColumnInTable =
    measurementResultsToPrint.length === 1
      ? ['43%', '29%', '15%', '13%']
      : measurementResultsToPrint.length === 2
      ? ['39%', '18%', '18%', '12%', '13%']
      : ['33%', '15%', '15%', '15%', '9%', '13%'] //→ measurementResultsToPrint.length === 3
  const tableHeader = [{ text: '検査項目', margin: [0, 7, 0, 0] }]
  const baseTableBody = []
  let index = 0
  for (const measurementResult of measurementResultsToPrint) {
    // tableHeader
    const datetimeExaminationNameColumn = {
      stack: [
        {
          text: moment(measurementResult.date, 'YYYYMMDD').format('Y年M月D日')
        },
        { text: measurementResult.examinationName }
      ]
    }
    tableHeader.push(datetimeExaminationNameColumn)

    // tableBody
    for (const measurementResultItem of measurementResult.measurementResultItemsToPrint) {
      const {
        equalSign,
        value,
        unit,
        name,
        referenceRange,
        masterLaboratoryItemCode
      } = measurementResultItem
      const valueColumn = self.makeValueColumn(equalSign, value, referenceRange)
      const rewriteColumnIndex = 1 + index // 測定値(value)を入れる列の位置。検査項目の列があるため1を足す
      const sameNameRowIndex = baseTableBody.findIndex(
        row => row.masterLaboratoryItemCode === masterLaboratoryItemCode
      )
      if (sameNameRowIndex !== -1) {
        // measurementResultsToPrintの1回目のループ(index === 0)ではbaseTableBodyは空の配列のため、このif文内の処理を通ることはない
        baseTableBody[sameNameRowIndex].printCells[
          rewriteColumnIndex
        ] = valueColumn
      } else {
        const row = makeBaseTableRow({
          name,
          unit,
          referenceRange,
          masterLaboratoryItemCode,
          measurementResultsToPrint
        })
        row.printCells[rewriteColumnIndex] = valueColumn
        baseTableBody.push(row)
      }
    }
    index += 1
  }
  const tableBody = baseTableBody.map(v => v.printCells)
  tableHeader.push(
    { text: '単位', margin: [0, 7, 0, 0] },
    { text: '基準値', margin: [0, 7, 0, 0] }
  )
  const measurementResultTable = {
    alignment: 'center',
    fontSize: 10,
    width: 'auto',
    layout: 'measurementResultTable',
    table: {
      headerRows: 1,
      dontBreakRows: true,
      widths: widthsOfEachColumnInTable,
      body: [tableHeader, ...tableBody]
    }
  }

  //全体
  const content = [contentHeader, measurementResultTable]
  return {
    content,
    footer: (currentPage, pageCount) =>
      makeFooter({ currentPage, pageCount, fontSize: 11 }),
    defaultStyle: {
      font: 'NotoSansMono',
      fontSize: 12,
      color: '#3e3e3e',
      preserveLeadingSpaces: true
    },
    pageSize: makePageSize(paperSize),
    pageMargins: [20, 20, 20, 41],
    pageOrientation: 'portrait'
  }
}

// args = {
//   clinic,
//   clinicImage,
//   showClinicName,
//   owner,
//   patient,
//   speciesName,
//   measurementResultsToPrint
// }
export const printMeasurementResults = args => {
  const docDefinition = makeDocDefinition(args)
  const fonts = {
    NotoSansMono: {
      normal: 'NotoSansMonoCJKJPRegular.otf',
      bold: 'NotoSansMonoCJKJPRegular.otf',
      italics: 'NotoSansMonoCJKJPRegular.otf',
      bolditalics: 'NotoSansMonoCJKJPRegular.otf'
    }
  }
  const tableLayouts = {
    measurementResultTable: {
      paddingLeft: () => 1,
      paddingRight: () => 1,
      hLineColor: '#3e3e3e',
      vLineColor: '#3e3e3e',
      fillColor: (rowIndex, node, colIndex) =>
        rowIndex === 0 ? '#f0f0f0' : null
    }
  }
  createPdf(docDefinition, tableLayouts, fonts).open()
}

const makeBaseTableRow = ({
  name,
  unit,
  referenceRange,
  masterLaboratoryItemCode,
  measurementResultsToPrint
}) => {
  const nameUnitFontSize =
    measurementResultsToPrint.length === 3 ? { fontSize: 9 } : {}
  const nameColumn = { text: name, ...nameUnitFontSize }
  const unitColumn = { text: unit, ...nameUnitFontSize }
  const referenceRangeColumn = { text: referenceRange }
  const printCells = [nameColumn]
  for (let i = 0; i < measurementResultsToPrint.length; i++) {
    printCells.push({ text: '' })
  }
  printCells.push(unitColumn)
  printCells.push(referenceRangeColumn)
  return { masterLaboratoryItemCode, printCells }
}
