<template>
  <focus-trap :escapeDeactivates="false" :clickDeactivates="false">
    <div class="estimate-popup" @keyup.esc.stop="closePopup" tabindex="-2">
      <popup
        :buttons="['閉じる', '診療明細にコピー']"
        @cancel="closePopup"
        @decision="copyToMedicalPayment"
        @click-close-mark="closePopup"
      >
        <template v-slot:content>
          <div class="content">
            <div class="title">見積書</div>
            <div class="show">
              <div class="date-staff">
                <div class="item date">
                  <div class="label">見積日</div>
                  <div class="value">{{ estimate.date }}</div>
                </div>
                <div class="item staff">
                  <div class="label">担当者</div>
                  <div class="value" :title="makeStaffName(estimate.staffId)">
                    {{ makeStaffName(estimate.staffId) }}
                  </div>
                </div>
              </div>
              <div class="diseases">
                <div class="item disease1">
                  <div class="label">診断1</div>
                  <div class="value">
                    <div class="disease-class">
                      <div class="disease-class-label">疾患名</div>
                      <div
                        class="disease-class-value"
                        :title="makeName(estimate.diseaseClass1)"
                      >
                        {{ makeName(estimate.diseaseClass1) }}
                      </div>
                    </div>
                    <div class="disease">
                      <div class="disease-label">症状名</div>
                      <div
                        class="disease-value"
                        :title="makeName(estimate.disease1)"
                      >
                        {{ makeName(estimate.disease1) }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="item disease2">
                  <div class="label">診断2</div>
                  <div class="value">
                    <div class="disease-class">
                      <div class="disease-class-label">疾患名</div>
                      <div
                        class="disease-class-value"
                        :title="makeName(estimate.diseaseClass2)"
                      >
                        {{ makeName(estimate.diseaseClass2) }}
                      </div>
                    </div>
                    <div class="disease">
                      <div class="disease-label">症状名</div>
                      <div
                        class="disease-value"
                        :title="makeName(estimate.disease2)"
                      >
                        {{ makeName(estimate.disease2) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="insurance-apply">
                <div class="apply" data-test="apply">
                  <div class="label">保険適用</div>
                  <div class="value">
                    {{ estimate.applyFlg === 1 ? '有り' : '無し' }}
                  </div>
                </div>
                <div v-if="insuranceDetail" class="detail" data-test="detail">
                  <div class="part jp-insurance">
                    <div class="label">保険</div>
                    <div class="value">{{ insuranceDetail.jpInsurance }}</div>
                  </div>
                  <div class="part policy-number">
                    <div class="label">証券番号</div>
                    <div class="value">{{ insuranceDetail.policyNumber }}</div>
                  </div>
                </div>
              </div>
              <div class="surgery">
                <div class="label">手術</div>
                <div class="value">
                  {{ estimate.surgeryFlg === 1 ? '有り' : '無し' }}
                </div>
              </div>
              <div class="estimate-treatment-items">
                <data-table
                  v-bind="treatmentItemData"
                  :items="displayTreatmentItems"
                />
              </div>
              <div class="estimate-price-table">
                <data-table
                  v-bind="priceDetailData"
                  :items="displayPriceDetails"
                />
              </div>
              <div class="bill">
                <div class="item insurance">
                  <div class="label">保険請求額</div>
                  <div class="value">
                    {{ displayPriceDetail.insurancePrice }}
                  </div>
                </div>
                <div class="item owner">
                  <div class="label">飼主請求額</div>
                  <div class="value">{{ displayPriceDetail.ownerBill }}</div>
                </div>
              </div>
              <div class="memo">
                <div class="label">備考</div>
                <div
                  v-show="estimate.applyFlg === 1"
                  class="warning"
                  data-test="memo"
                >
                  保険請求額、飼主請求額は変更になる可能性があります。
                </div>
                <div class="value">{{ estimate.memo }}</div>
              </div>
            </div>
          </div>
        </template>
      </popup>
    </div>
  </focus-trap>
</template>

<script>
import Popup from '@/components/popups/Popup'
import DataTable from '@/components/parts/organisms/DataTable'
import { FocusTrap } from 'focus-trap-vue'
import { mapGetters } from 'vuex'
import { makePriceDetail } from '@/utils/price_calculation'

const treatmentItemStyles = [
  { width: '27%', textAlign: 'left', padding: '0 10px' },
  { width: '6%' },
  { width: '15%' },
  { width: '10%' },
  { width: '12%' },
  { width: '15%' },
  { width: '15%' }
].map(v => {
  return { ...v, fontSize: '11px' }
})
const priceDetailStyles = [...Array(5).keys()].map(v => {
  return { ...v, fontSize: '11px' }
})

export default {
  name: 'EstimatePopup',

  components: {
    Popup,
    DataTable,
    FocusTrap
  },

  props: {
    estimate: { type: Object }
  },

  data() {
    return {
      treatmentItemData: {
        keys: [
          'name',
          'insuranceFlg',
          'unitPrice',
          'amount',
          'discountRate',
          'discountPrice',
          'subtotal'
        ],
        headers: [
          '診療項目内容',
          '保険',
          '単価（￥）',
          '数量',
          '割引（%）',
          '値引（￥）',
          '小計（￥）'
        ],
        headerCellStyles: treatmentItemStyles,
        bodyCellStyles: treatmentItemStyles.map((v, i) => {
          return { ...v, textAlign: i === 1 ? 'center' : v.textAlign }
        }),
        bodyStyle: { maxHeight: '120px' },
        scrollFlg: true,
        stripeFlg: true
      },
      priceDetailData: {
        keys: [
          'totalPrice',
          'rateDiscountedPrice',
          'fixedDiscountedPrice',
          'taxIncludedPrice',
          'bill'
        ],
        headers: [
          '診療費 小計',
          '割引適用額',
          '値引適用額',
          '消費税',
          '請求額'
        ],
        headerCellStyles: priceDetailStyles,
        bodyCellStyles: priceDetailStyles,
        stripeFlg: true
      }
    }
  },

  computed: {
    ...mapGetters({
      staffById: 'staffs/getDataById'
    }),
    displayTreatmentItems() {
      return this.estimate.treatmentItems.map(v => {
        return {
          ...v,
          insuranceFlg: v.insuranceFlg === 1 ? '◯' : '✕',
          unitPrice: `￥${v.unitPrice.toLocaleString()}`,
          discountRate: `${v.discountRate.toLocaleString()} %`,
          discountPrice: `￥${v.discountPrice.toLocaleString()}`,
          subtotal: `￥${v.subtotal.toLocaleString()}`
        }
      })
    },
    displayPriceDetail() {
      const priceDetail = makePriceDetail(
        this.estimate,
        this.estimate.surgeryFlg, //← 0 or 1
        1,
        this.estimate.treatmentItems
      )
      priceDetail.bill = priceDetail.taxIncludedPrice
      return Object.fromEntries(
        Object.entries(priceDetail).map(([key, value]) => {
          return key === 'pledgeRate' || key === 'discountRate'
            ? [key, `${value} %`]
            : key === 'rateDiscountedPrice' &&
              this.estimate.insuranceType === 'ipet-docomo'
            ? [key, '']
            : [key, `￥${value}`]
        })
      )
    },
    displayPriceDetails() {
      return [
        {
          totalPrice: '',
          rateDiscountedPrice: `割引率 ${this.displayPriceDetail.discountRate}`,
          fixedDiscountedPrice: `値引額 ${this.displayPriceDetail.discountPrice}`,
          taxIncludedPrice: this.displayPriceDetail.consumptionTax,
          bill: ''
        },
        this.displayPriceDetail
      ]
    },
    insuranceDetail() {
      let insuranceDetail = null
      if (this.estimate.applyFlg === 1) {
        let jpInsurance = ''
        let policyNumber = ''
        if (this.anicomCIdCheck) {
          jpInsurance = 'アニコム'
          policyNumber = this.anicomCIdCheck.patientCId
        } else if (this.ipetCheck) {
          const { insurance } = this.ipetCheck
          if (insurance === 'ipet') jpInsurance = 'アイペット'
          else if (insurance === 'docomo') jpInsurance = 'ドコモ'
          policyNumber = this.ipetCheck.policyNo
        }
        insuranceDetail = { jpInsurance, policyNumber }
      }
      return insuranceDetail
    },
    anicomCIdCheck() {
      return this.$store.getters['anicomCIdChecks/getDataById'](
        this.estimate.anicomCIdCheckId
      )
    },
    ipetCheck() {
      return this.$store.getters['ipetChecks/getDataById'](
        this.estimate.ipetCheckId
      )
    }
  },

  methods: {
    makeStaffName(staffId) {
      const staff = this.staffById(staffId)
      const staffName = staff ? staff.lastName + ' ' + staff.firstName : ''
      return staffName
    },
    makeName(item) {
      return item ? item.name : ''
    },
    closePopup() {
      this.$emit('close')
    },
    copyToMedicalPayment() {
      const estimate = this.estimate
      const estimateTreatmentItems = this.estimate.treatmentItems
      this.$emit('copy', estimate, estimateTreatmentItems)
    }
  }
}
</script>

<style lang="scss" scoped>
.estimate-popup {
  .content {
    box-sizing: border-box;
    font-size: 13px;
    > .title {
      font-size: 16px;
      font-weight: bold;
      color: #{$greyish-brown};
      text-align: left;
    }
    > .show {
      margin-top: 15px;
      position: relative;
      > .date-staff {
        display: flex;
        height: 40px;
        padding: 0 10px;
        border: solid #{$light-grey};
        border-width: 1px 0;
        background-color: #{$gray_f8};
        box-sizing: border-box;
        > .item {
          display: flex;
          align-items: center;
          flex: 1;
          width: 0;
          > .label {
            width: 50px;
          }
          > .value {
            flex: 1;
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      > .diseases {
        height: 70px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding: 5px 10px;
        > .item {
          flex: 1;
          display: flex;
          align-items: center;
          > .label {
            width: 55px;
          }
          > .value {
            box-sizing: border-box;
            width: 565px;
            display: flex;
            align-items: center;
            > .disease-class {
              width: 226px;
            }
            > .disease {
              flex: 1;
              padding-left: 10px;
            }
            > .disease-class,
            .disease {
              display: flex;
              > .disease-class-label,
              .disease-label {
                width: 50px;
              }
              > .disease-class-value,
              .disease-value {
                flex: 1;
                width: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }
        }
      }
      > .insurance-apply {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 0 30px;
        padding: 0 10px;
        width: 100%;
        height: 57px;
        border: solid #{$light-grey};
        border-width: 1px 0;
        background-color: #{$gray_f8};
        font-size: 13px;
        > .apply {
          display: flex;
          align-items: center;
          gap: 0 15px;
        }
        > .detail {
          display: flex;
          align-items: center;
          gap: 0 25px;
          > .part {
            > .label {
              height: 20px;
            }
            > .value {
              height: 20px;
            }
          }
        }
      }
      > .surgery {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 10px;
        font-size: 13px;
        > .label {
          margin-right: 15px;
        }
      }
      > .estimate-treatment-items {
        margin-top: 5px;
        height: 640;
      }
      > .estimate-price-table {
        margin-top: 15px;
        width: 100%;
      }
      > .bill {
        margin-top: 15px;
        box-sizing: border-box;
        display: flex;
        height: 40px;
        border: 1px solid #{$pumpkin};
        > .item {
          flex: 1;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          &.owner {
            color: #{$pumpkin};
          }
          > .label {
            font-size: 13px;
            font-weight: bold;
          }
          > .value {
            font-size: 15px;
          }
        }
        > .item ~ .item {
          border-left: 1px solid #{$pumpkin};
        }
      }
      > .memo {
        margin-top: 10px;
        box-sizing: border-box;
        font-size: 13px;
        > .label {
          margin-right: 15px;
          font-weight: bold;
        }
        > .value {
          box-sizing: border-box;
          overflow: auto;
          margin-top: 2px;
          height: 66px;
          padding: 2px 5px;
          border-radius: 4px;
          border: solid 1px #{$light-grey};
          white-space: pre-wrap;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>
