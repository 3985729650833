<template>
  <div class="medical-record-edit">
    <base-loading :waitFlg="waitFlg" />
    <unsaved-leave-popup :layerNumber="4" />
    <div class="windows" ref="windows">
      <medical-content-input-form
        ref="medicalContentInputForm"
        v-show="mixinMedicalContentInputFormShowFlg"
        :patientId="patientId"
        :displayOwner="mixinOwner"
        :medicalRecord="medicalRecord"
        :medicalContent="medicalContent"
        :waitFlg="waitFlg"
        :waitFlgForGetUploadImages="waitFlgForGetUploadImages"
        :isCheckingApply="mixinIsCheckingApply"
        :expansionDisplayFlg="mixinExpansionMedicalContentInputFormFlg"
        :dateDisabled="mixinDateChangeNg"
        :startEndMinMax="mixinStartEndMinMax"
        :overlappedTimeErrorMessage="mixinOverlappedTimeErrorMessage"
        :hospitalizationUpdateFlg="mixinHospitalizationUpdateFlg"
        :removeApplyFlgError="mixinRemoveApplyFlgError"
        @input-medical-record="mixinInputMedicalRecord"
        @click-menu-item="mixinShowKarteRelatedContent"
        @click-examination-result-table="mixinClickExaminationResultTable"
        @create-medical-content="updateMedicalContent"
        @set-to-initial-medical-content="
          mixinSetToInitialMedicalContentAfterWatch
        "
        @edit-medical-content-image="mixinEditMedicalContentImage"
        @expansion="mixinExpansionMedicalContentInputForm"
        @reduction="mixinReductionMedicalContentInputForm"
        @unsaved-resized-image="unsavedResizedImage"
      />
      <medical-contents-history
        class="window"
        v-show="mixinMedicalContentsHistoryShowFlg"
        v-if="mixinMedicalContentsHistoryFlg"
        :patientId="patientId"
        :style="mixinMovedStyleMedicalContentsHistory"
        @failed-get-history-images="mixinFailedGetHistoryImages"
        @close="mixinCloseMedicalContentsHistory"
        @move="mixinMoveMedicalContentsHistory"
      />
      <div
        v-if="mixinMedicalContentsHistoryFootPrintFlg"
        class="foot-print window"
      ></div>
      <medical-payment-input-form
        class="window medical-payment-input-form"
        v-show="mixinMedicalPaymentInputFormShowFlg"
        :patientId="patientId"
        :patient="mixinPatient"
        :displayOwner="mixinOwner"
        :anicomPatient="mixinAnicomPatient"
        :ipetPatient="mixinIpetPatient"
        :medicalRecord="medicalRecord"
        :medicalPayment="medicalPayment"
        :medicalTreatmentItems="medicalTreatmentItems"
        :anicomCIdCheck="mixinAnicomCIdCheck"
        :ipetCheck="mixinIpetCheck"
        :waitFlg="waitFlg"
        :waitFlgForGetUploadImages="waitFlgForGetUploadImages"
        :isCheckingApply="mixinIsCheckingApply"
        :expansionFlg="mixinExpansionMedicalPaymentInputFormFlg"
        :style="mixinClearMargin"
        :pastHospitalizationMedicalPayments="
          mixinPastHospitalizationMedicalPayments
        "
        :priceDetail="mixinPriceDetail"
        :hospitalizationInfo="mixinHospitalizationInfo"
        :initialMedicalPayment="mixinInitialData.medicalPayment"
        :overlappedTimeErrorMessage="mixinOverlappedTimeErrorMessage"
        :hospitalizationUpdateFlg="mixinHospitalizationUpdateFlg"
        :removeApplyFlgError="mixinRemoveApplyFlgError"
        @click-medical-payments="
          mixinMedicalPaymentsHistoryFlg = !mixinMedicalPaymentsHistoryFlg
        "
        @input-apply-flg="mixinInputApplyFlg"
        @updated-insurance="mixinSetInsurance"
        @input-in-hospital-flg="mixinInputInHospitalFlg"
        @input-start-hospitalization-flg="mixinInputStartHospitalizationFlg"
        @input-middle-calculate-flg="mixinInputMiddleCalculateFlg"
        @input-end-hospitalization-flg="mixinInputEndHospitalizationFlg"
        @order="mixinChangeOrder"
        @click-trash="mixinRemoveItem"
        @add-items="mixinAddItems"
        @change-item="mixinChangeItem"
        @copy="mixinCopyFromEstimate"
        @expansion="mixinExpansionMedicalPaymentInputForm"
        @reduction="mixinReductionMedicalPaymentInputForm"
        @click-payment="handlePaymentNavigation"
        @decision="mixinCheckHavingAnicomReport"
      />
      <medical-payments-history
        class="window"
        v-show="mixinMedicalPaymentsHistoryShowFlg"
        v-if="mixinMedicalPaymentsHistoryFlg"
        :patientId="patientId"
        :style="mixinMovedStyleMedicalPaymentsHistory"
        @copy="mixinConfirmMedicalPaymentCopy"
        @close="mixinCloseMedicalPaymentsHistory"
        @move="mixinMoveMedicalPaymentsHistory"
      />
      <div
        v-if="mixinMedicalPaymentsHistoryFootPrintFlg"
        class="foot-print window"
      ></div>
    </div>
    <problem-list-popup
      v-if="mixinProblemListPopupFlg"
      :patientId="patientId"
      @close="mixinProblemListPopupFlg = false"
    />
    <upload-image-popup
      :patientId="patientId"
      v-if="mixinUploadImagePopupFlg"
      @close="mixinUploadImagePopupFlg = false"
      @decide="mixinSetUploadImage"
    />
    <schema-popup
      v-if="mixinSchemaPopupFlg"
      :patientId="patientId"
      :medicalContentImageId="mixinMedicalContentImageId"
      @close="mixinCloseSchemaPopup"
      @decide="mixinSetMedicalContentImage"
    />
    <examination-list-popup
      v-if="mixinExaminationListPopupFlg"
      :examinationResultGroups="examinationResultGroups"
      :examinationResultGroupsIncludeDel="examinationResultGroupsIncludeDel"
      :patientId="patientId"
      :medicalRecordDate="medicalRecord.date"
      @close="mixinExaminationListPopupFlg = false"
      @copy="mixinSetExaminationResults"
    ></examination-list-popup>
    <examination-popup
      v-if="mixinExaminationPopupFlg"
      :treatment="mixinChangeResultTreatment"
      :format="mixinChangeResultFormat"
      :changeExaminationResultGroupId="mixinChangeExaminationResultGroupId"
      :examinationResultGroups="examinationResultGroups"
      :patientId="patientId"
      @close="mixinCloseExaminationPopup"
      @copy="mixinChangeExaminationResults"
    ></examination-popup>
    <medical-content-templates-popup
      v-if="mixinMedicalContentTemplatesPopupFlg"
      @close="mixinMedicalContentTemplatesPopupFlg = false"
      @set="mixinSetTemplate"
    />
    <announce-popup
      class="medical-content-popup"
      v-if="mixinAlertFlg"
      :title="mixinTitle"
      :buttons="mixinButtons"
      :type="mixinType"
      @close="mixinCloseContentPopup"
      @cancel="mixinCloseAlertPopup"
      @decision="mixinPopup.decision"
      >{{ mixinPopupMessage }}</announce-popup
    >
    <announce-popup
      class="medical-payment-popup"
      v-if="popupFlg"
      :type="type"
      :title="title"
      :leftAlignMessage="leftAlignMessage"
      :buttons="buttons"
      :disabled="waitFlg"
      @close="mixinClosePaymentPopup"
      @cancel="mixinClosePaymentPopup"
      @decision="decision"
      >{{ popupMessage }}
      <template #button v-if="showValidityCheckButton">
        <div class="slot-button">
          <base-button-border-orange @click="mixinConfirmAnicomUrlOpen"
            >有効性確認システム</base-button-border-orange
          >
        </div>
      </template></announce-popup
    >
    <kick-out-popup />
  </div>
</template>

<script>
import MedicalContentInputForm from '@/components/parts/organisms/MedicalContentInputForm'
import MedicalContentsHistory from '@/components/parts/organisms/MedicalContentsHistory'
import MedicalPaymentInputForm from '@/components/parts/organisms/MedicalPaymentInputForm'
import MedicalPaymentsHistory from '@/components/parts/organisms/MedicalPaymentsHistory'
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import UnsavedLeavePopup from '@/components/popups/UnsavedLeavePopup'
import CheckInputDifference from '@/components/mixins/CheckInputDifference'
import CommonMedicalContentNewEdit from '@/components/mixins/CommonMedicalContentNewEdit'
import ProblemListPopup from '@/components/popups/ProblemListPopup'
import UploadImagePopup from '@/components/popups/UploadImagePopup'
import SchemaPopup from '@/components/popups/SchemaPopup'
import ExaminationListPopup from '@/components/popups/ExaminationListPopup'
import ExaminationPopup from '@/components/popups/ExaminationPopup'
import MedicalContentTemplatesPopup from '@/components/popups/MedicalContentTemplatesPopup'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import MedicalPaymentNewEdit from '@/components/mixins/MedicalPaymentNewEdit'
import KickOutPopup from '@/components/popups/KickOutPopup'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'MedicalRecordEdit',

  components: {
    MedicalContentInputForm,
    MedicalContentsHistory,
    MedicalPaymentInputForm,
    MedicalPaymentsHistory,
    ExaminationListPopup,
    ExaminationPopup,
    AnnouncePopup,
    UnsavedLeavePopup,
    ProblemListPopup,
    UploadImagePopup,
    SchemaPopup,
    MedicalContentTemplatesPopup,
    BaseButtonBorderOrange,
    BaseLoading,
    KickOutPopup
  },

  mixins: [
    CheckInputDifference,
    CommonMedicalContentNewEdit,
    MedicalPaymentNewEdit
  ],

  props: {
    ownerId: { type: Number },
    patientId: { type: Number },
    originalId: { type: Number }
  },

  data() {
    return {
      medicalRecord: {},
      medicalContent: {},
      examinationResultGroups: {},
      examinationResultGroupsIncludeDel: {},
      medicalPayment: {
        patientId: this.patientId,
        staffId: 0,
        diseaseClass1Id: 0,
        disease1Id: 0,
        diseaseClass2Id: 0,
        disease2Id: 0,
        insuranceType: '',
        applyFlg: 0,
        anicomCIdCheckId: 0,
        ipetCheckId: 0,
        rezeptCd: '',
        pledgeRate: 0,
        reason01: 0,
        reason02: 0,
        reason03: 0,
        reason04: 0,
        reason05: 0,
        reason06: 0,
        reason07: 0,
        reason08: 0,
        reason09Txt: '',
        onsetDate: '',
        onsetAroundFlg: 0,
        uncertainOnsetFlg: 0,
        surgeryFlg: 0,
        startHospitalizationFlg: 0,
        inHospitalFlg: 0,
        middleCalculateFlg: 0,
        endHospitalizationFlg: 0,
        discountRate: 0,
        discountPrice: 0,
        burdenAmount: 0
      },
      medicalTreatmentItems: [],
      hospitalizationDetail: {},
      popupFlg: false,
      type: '',
      title: '',
      popupMessage: '',
      leftAlignMessage: '',
      buttons: [],
      decision: () => {},
      waitFlg: false,
      waitFlgForGetUploadImages: false,
      noDataFlg: false,
      medicalPaymentsShowFlg: false,
      showValidityCheckButton: false,
      paymentNewFlg: false
    }
  },

  computed: {
    ...mapGetters({
      getMedicalRecord: 'medicalRecords/getDataByOriginalId',
      getMedicalContentsByRecordOriginalId:
        'medicalContents/getDataByMedicalRecordOriginalId',
      getExaminationResultsByPatientId: 'examinationResults/getDataByPatientId',
      medicalPaymentsByRecordOriginalId:
        'medicalPayments/getDataByMedicalRecordOriginalId',
      medicalTreatmentItemsByMedicalPaymentId:
        'medicalTreatmentItems/getDataByMedicalPaymentId',
      karteUploadImages: 'uploadImages/getKarteUploadImages',
      karteMedicalContentImages:
        'medicalContentImages/getKarteMedicalContentImages'
    })
  },

  async created() {
    this.$store.dispatch(
      'medicalRecords/setOriginalIdSetInTab',
      Number(this.originalId)
    )
    const medicalRecord = this.getMedicalRecord(this.originalId)
    if (!medicalRecord || medicalRecord.delFlg === 1) {
      // 削除してからブラウザバックで戻った時
      this.mixinToRecordNewFlg = true
      this.mixinType = 'alert'
      this.mixinTitle = '注意'
      this.mixinButtons = ['閉じる']
      this.mixinPopupMessage =
        '編集中のカルテは既に削除されています。\n新規登録画面へ移動します。'
      this.mixinAlertFlg = true
    }
    this.medicalRecord = { ...medicalRecord }
    this.medicalContent =
      this.getMedicalContentsByRecordOriginalId(this.originalId)?.length > 0
        ? { ...this.getMedicalContentsByRecordOriginalId(this.originalId)[0] }
        : { patientId: this.patientId, content: '<p></p>' }
    const examinationResultsByPatient = this.getExaminationResultsByPatientId(
      this.patientId
    )
    if (examinationResultsByPatient) {
      const examinationResultsByMedicalContent = examinationResultsByPatient.filter(
        v => v.medicalContentId === this.medicalContent.id
      )
      this.examinationResultGroups =
        examinationResultsByMedicalContent.length > 0
          ? this.mixinMakeExaminationResultGroups(
              examinationResultsByMedicalContent
            )
          : {}
    } else {
      this.examinationResultGroups = {}
    }
    this.examinationResultGroupsIncludeDel = this.examinationResultGroups
    this.$store.dispatch(
      'examinationResults/setNewExaminationResultGroups',
      this.examinationResultGroups
    )
    this.medicalPayment =
      this.medicalPaymentsByRecordOriginalId(this.originalId)?.length > 0
        ? { ...this.medicalPaymentsByRecordOriginalId(this.originalId)[0] }
        : this.medicalPayment
    this.medicalTreatmentItems = [
      ...(this.medicalTreatmentItemsByMedicalPaymentId(
        this.medicalPayment.id
      ) || [])
    ].map((v, i) => {
      return { ...v, key: i }
    })
    this.hospitalizationDetail = this.mixinMakeHospitalizationDetail()
    this.mixinSetInsurance()
    this.mixinInputData = {
      medicalRecord: this.medicalRecord,
      medicalContent: this.medicalContent,
      examinationResultGroups: this.examinationResultGroups,
      medicalPayment: this.medicalPayment,
      medicalTreatmentItems: this.medicalTreatmentItems,
      resizedImageDiff: false
    }
    this.mixinSetInitialData()

    // 開いたカルテに紐づいている画像（高解像度の画像の方、uploadImageのimageカラム）をapiで取得する。但し、既に取得している画像は再度apiで取得しないようにしている。
    const requiredUploadImageIds = this.getRequiredUploadImageIdsOfOpenedKarte()
    if (requiredUploadImageIds.length > 0) {
      this.fetchUploadImagesOfOpenedKarte(requiredUploadImageIds)
    }
  },

  beforeDestroy() {
    this.$store.dispatch('examinationResults/resetNewExaminationResultGroups')
    this.$store.dispatch('uploadImages/resetResizedUploadImages')
    this.$store.dispatch(
      'medicalContentImages/resetResizedMedicalContentImages'
    )
  },

  methods: {
    getUploadImageIds() {
      if (!this.medicalContent.id) return []
      /*
        開いている診療内容に紐づいているuploadImageのidをstoreから取得する処理。
        この処理が走る前に、患者に紐づいたカルテ関連の各データを取得するapi(/medical-records/patient-all-data)で、患者に紐づいたuploadImage(imageカラムは入っていない)を全てフロント側に持ってきているため、storeのstateから取得できる。
      */
      return Object.values(this.karteUploadImages)
        .filter(
          uploadImage =>
            uploadImage.delFlg === 0 &&
            uploadImage.medicalContentOriginalId ===
              this.medicalContent.originalId
        )
        .map(uploadImage => uploadImage.id)
    },
    getUploadImageIdsOfMedicalContentImage() {
      if (!this.medicalContent.id) return []
      /*
        開いている診療内容の各シェーマ画像の背景で使用しているuploadImageのidを取得する処理。
        値をバックエンドに送って該当のuploadImageを取得する。
        目的
        →シェーマ画像で他の診療内容のuploadImageを背景で使用した時に、
         そのuploadImageはシェーマ画像をセットした診療内容には紐づいていないため、
         uploadImageのmedicalContentOriginalId経由では取得できない。
         そのため、medicalContentImageのuploadImageIdから該当のuploadImageを取得する必要がある。
      */
      return Object.values(this.karteMedicalContentImages)
        .filter(
          medicalContentImage =>
            medicalContentImage.delFlg === 0 &&
            medicalContentImage.uploadImageId > 0 &&
            medicalContentImage.medicalContentOriginalId ===
              this.medicalContent.originalId
        )
        .map(medicalContentImage => medicalContentImage.uploadImageId)
    },
    getRequiredUploadImageIdsOfOpenedKarte() {
      const uploadImageIdsOfUploadImage = this.getUploadImageIds()
      const uploadImageIdsOfMedicalContentImage = this.getUploadImageIdsOfMedicalContentImage()
      const uploadImageIds = Array.from(
        new Set(
          uploadImageIdsOfUploadImage.concat(
            uploadImageIdsOfMedicalContentImage
          )
        )
      )
      const acquiredUploadImageIdsSet = this.$store.getters[
        'uploadImages/getAcquiredUploadImageIdsSet'
      ]
      const requiredUploadImageIds = uploadImageIds.filter(
        v => !acquiredUploadImageIdsSet.has(v)
      )
      return requiredUploadImageIds
    },
    async fetchUploadImagesOfOpenedKarte(requiredUploadImageIds) {
      /*
        開いている診療内容内にある高解像度の画像（uploadImageのimageカラム）を取得する処理。
        imageカラムが入ったuploadImageごと取得してstoreのstateにセットしている。
      */
      this.waitFlgForGetUploadImages = true
      const results = await Promise.all(
        requiredUploadImageIds.map(uploadImageId =>
          this.$store.dispatch(
            'uploadImages/fetchUploadImageOfOpenedKarte',
            uploadImageId
          )
        )
      )
      const isSomeApiFailed = results.some(result => result !== 'success')
      if (isSomeApiFailed) {
        this.mixinToOwnerShowFlg = true
        this.mixinType = 'alert'
        this.mixinTitle = '注意'
        this.mixinButtons = ['閉じる']
        this.mixinPopupMessage =
          'カルテ内にある画像の取得に失敗しました。\n飼主の詳細画面へ移動します。'
        this.mixinAlertFlg = true
      }
      this.waitFlgForGetUploadImages = false
    },
    handlePaymentNavigation() {
      this.medicalPayment.id
        ? this.mixinDiffFlg
          ? this.mixinConfirmMedicalPaymentRegister()
          : this.gotoPaymentPage()
        : this.mixinConfirmMedicalPaymentRegister()
    },
    gotoPaymentPage() {
      const ownerId = this.ownerId
      const patientId = this.patientId
      const medicalPaymentOriginalId = this.medicalPayment.originalId
      const patientPaymentsPath = `/main/karte/owners/${ownerId}/patients/${patientId}/payments/${medicalPaymentOriginalId}`
      this.mixinPaymentType === '未会計'
        ? this.$router.push(`${patientPaymentsPath}/new`)
        : this.$router.push(`${patientPaymentsPath}/show`)
    },
    unsavedResizedImage() {
      this.mixinInputData.resizedImageDiff = true
    },
    updateMedicalContent() {
      this.mixinInputData.resizedImageDiff = false
      this.mixinCreateUpdateMedicalContent()
    }
  }
}
</script>

<style lang="scss" scoped>
.medical-record-edit {
  display: inline-block;
  min-width: 100%;
  > .windows {
    display: flex;
    position: relative;
    > .window {
      margin-left: 30px;
      max-height: 881px; // https://zpl.io/29Mogly での高さに準拠
      &.medical-payment-input-form {
        max-height: none;
      }
    }
    > .foot-print {
      width: 650px;
      height: 100%;
    }
  }
}
.slot-button {
  margin: 0px 20px 20px 20px;
}
</style>
