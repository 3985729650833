<template>
  <div class="patient-measurement-results">
    <base-loading :waitFlg="waitFlg" />
    <div class="area unlinked">
      <base-button-border-orange
        :styles="{ width: '120px' }"
        @click="getUnlinkedData"
        >検査機器連携</base-button-border-orange
      >
    </div>
    <div class="area owner-patient">
      <owner-patient-detail :owner="owner" :patient="patient" />
    </div>
    <div class="area linked">
      <div class="title">連携済データ</div>
      <div class="buttons" v-if="printFlg">
        <base-button-small-white
          :styles="{ width: '120px' }"
          @click="cancelPrintMode"
          >キャンセル</base-button-small-white
        >
        <base-button-border-orange
          :styles="{ width: '120px' }"
          :disabled="checkboxStatuses.filter(v => v.isChecked).length === 0"
          @click="print"
          >印刷</base-button-border-orange
        >
      </div>
      <base-button-border-orange
        v-if="!printFlg"
        :styles="{ width: '120px' }"
        @click="toPrintMode"
        >連携済データ印刷</base-button-border-orange
      >
    </div>
    <div class="area search">
      <search-area
        :textBoxLabel="'検索単語'"
        :textValue="searchWord"
        :placeholder="'検査機器'"
        :periodFlg="true"
        :periodLabel="'期間'"
        @input-start-date="inputDate($event, 'start')"
        @input-end-date="inputDate($event, 'end')"
        @clear="clearDate"
        v-model="searchWord"
      />
    </div>
    <div class="area search-result">
      <div>
        検索結果：{{ filteredMeasurementResults.length }}件({{
          linkedMeasurementResults.length
        }}件中)
      </div>
    </div>
    <div class="area list">
      <measurement-result-list-table
        :measurementResults="filteredMeasurementResults"
        :printFlg="printFlg"
        :checkboxStatuses="checkboxStatuses"
        :disabledCheckBoxTitle="disabledCheckBoxTitle"
        :shouldDisableCheckBoxes="shouldDisableCheckBoxes"
        @check="check"
        @click-item="openMeasurementResultPopup"
      />
    </div>
    <announce-popup
      v-if="announcePopup.isOpened"
      v-bind="announcePopup"
      :layer-number="2"
      @close="closeAnnouncePopup"
      >{{ announcePopup.message }}</announce-popup
    >
    <measurement-result-popup
      v-if="showMeasurementResultPopup"
      :buttons="lookOnlyFlg ? ['閉じる'] : ['閉じる', '関連付け解除']"
      :can-delete="true"
      :disabled-flg="waitFlg"
      :measurement-result-id="selectedMeasurementResultId"
      @close="closeMeasurementResultPopup"
      @decide="updatePatientId"
    />
    <unlinked-measurement-results-popup
      v-if="unlinkedMeasurementResultsPopupFlg"
      :patientId="patientId"
      @close="unlinkedMeasurementResultsPopupFlg = false"
    />
  </div>
</template>

<script>
import AnnouncePopup from '@/components/popups/AnnouncePopup'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonSmallWhite from '@/components/parts/atoms/BaseButtonSmallWhite'
import BaseLoading from '@/components/parts/atoms/BaseLoading'
import MeasurementResultListTable from '@/components/parts/organisms/MeasurementResultListTable'
import MeasurementResultPopup from '@/components/popups/MeasurementResultPopup'
import OwnerPatientDetail from '@/components/parts/molecules/OwnerPatientDetail'
import SearchArea from '@/components/parts/molecules/SearchArea'
import UnlinkedMeasurementResultsPopup from '@/components/popups/UnlinkedMeasurementResultsPopup'
import { compareByKeys } from '@/utils/compare'
import { printMeasurementResults } from '@/utils/print_measurement_results'
import { findMasterLaboratoryItemRange } from '@/utils/measurement_utils'
import { mapGetters } from 'vuex'

export default {
  name: 'PatientMeasurementResults',

  components: {
    AnnouncePopup,
    BaseButtonBorderOrange,
    BaseButtonSmallWhite,
    BaseLoading,
    MeasurementResultListTable,
    MeasurementResultPopup,
    OwnerPatientDetail,
    SearchArea,
    UnlinkedMeasurementResultsPopup
  },

  props: { ownerId: { type: Number }, patientId: { type: Number } },

  data() {
    return {
      announcePopup: {
        isOpened: false,
        type: 'failure',
        title: '失敗',
        message: '',
        buttons: ['閉じる']
      },
      canPrintMaxCounts: 3,
      checkboxStatuses: [],
      printFlg: false,
      searchWord: '',
      selectedMeasurementResultId: 0,
      showMeasurementResultPopup: false,
      startDate: '',
      endDate: '',
      unlinkedMeasurementResultsPopupFlg: false,
      waitFlg: false
    }
  },

  computed: {
    ...mapGetters({
      clinic: 'clinic/getData',
      printSetting: 'printSetting/getData',
      getMasterDatum: 'master/getDataById',
      getOwner: 'owners/getDataById',
      getPatient: 'patients/getDataById',
      getSpecies: 'species/getDataById',
      getMasterLaboratoryCategoryIndexedByCode:
        'master/getMasterLaboratoryCategoryIndexedByCode',
      getMasterLaboratoryItemUnitIndexedByCode:
        'master/getMasterLaboratoryItemUnitIndexedByCode',
      getMasterLaboratoryItemIndexedByCode:
        'master/getMasterLaboratoryItemIndexedByCode',
      getMasterLaboratoryMachineIndexedByCode:
        'master/getMasterLaboratoryMachineIndexedByCode',
      getMeasurementResultsByPatientId: 'measurementResults/getDataByPatientId',
      getMeasurementResultItemsByMeasurementResultId:
        'measurementResultItems/getDataByMeasurementResultId',
      lookOnlyFlg: 'auth/lookOnlyFlg'
    }),
    owner() {
      return this.getOwner(this.ownerId)
    },
    patient() {
      return this.getPatient(this.patientId)
    },
    linkedMeasurementResults() {
      return this.getMeasurementResultsByPatientId(this.patientId) || []
    },
    filteredMeasurementResults() {
      return this.linkedMeasurementResults
        .filter(v => this.filterByDate(v) && this.filterByWord(v))
        .sort((a, b) => compareByKeys(a, b, ['date', 'time', 'id']))
    },
    shouldDisableCheckBoxes() {
      if (this.printFlg) {
        const checkedCounts = this.checkboxStatuses.filter(v => v.isChecked)
          .length
        return this.canPrintMaxCounts <= checkedCounts
      } else {
        return true
      }
    },
    disabledCheckBoxTitle() {
      return '同時に印刷できるデータは' + this.canPrintMaxCounts + '件までです'
    }
  },

  watch: {
    filteredMeasurementResults: function() {
      if (this.printFlg) this.setCheckboxStatuses()
    }
  },

  methods: {
    async updatePatientId() {
      this.waitFlg = true
      const result = await this.$store.dispatch(
        'measurementResults/updatePatientId',
        {
          id: this.selectedMeasurementResultId,
          newPatientId: 0
        }
      )
      if (result === 'success') {
        this.closeMeasurementResultPopup()
      } else {
        if (
          result === 'no measurementResult' ||
          result === 'no data in clinic'
        ) {
          this.announcePopup.message =
            '患者との関連付けの解除に失敗しました。\n選択した項目は既に削除されています\n他の項目を選択して下さい。'
          this.closeMeasurementResultPopup()
        } else {
          this.announcePopup.message = '患者との関連付けの解除に失敗しました'
        }
        this.announcePopup.isOpened = true
      }
      this.waitFlg = false
    },
    async getUnlinkedData() {
      const result = await this.$store.dispatch(
        'measurementResults/getUnlinkedData'
      )
      if (result === 'success') {
        this.unlinkedMeasurementResultsPopupFlg = true
      } else {
        this.$store.dispatch('petorelu/showGlobalPopup', {
          show: true,
          type: 'failure',
          title: '失敗',
          buttons: ['閉じる'],
          message: '検査データの取得に失敗しました。',
          close: () => this.$store.dispatch('petorelu/hideGlobalPopup')
        })
      }
    },
    filterByDate(measurementResult) {
      const date = measurementResult.date
      if (this.startDate !== '' && this.endDate !== '') {
        return this.startDate <= date && date <= this.endDate
      } else if (this.startDate !== '') {
        return this.startDate <= date
      } else if (this.endDate !== '') {
        return date <= this.endDate
      } else {
        return true
      }
    },
    filterByWord(measurementResult) {
      const searchWord = this.searchWord.toLowerCase().trim()
      const deviceName = this.getMasterLaboratoryMachineIndexedByCode(
        measurementResult.masterLaboratoryMachineCode
      ).name
      return deviceName.toLowerCase().includes(searchWord)
    },
    inputDate(date, dateType) {
      dateType === 'start' ? (this.startDate = date) : (this.endDate = date)
    },
    clearDate() {
      this.startDate = ''
      this.endDate = ''
    },
    setCheckboxStatuses() {
      this.checkboxStatuses = this.filteredMeasurementResults.map(
        measurementResult => {
          const existingCheckboxStatus = this.checkboxStatuses.find(
            checkboxStatus =>
              checkboxStatus.measurementResultId === measurementResult.id
          )
          return {
            measurementResultId: measurementResult.id,
            isChecked: existingCheckboxStatus?.isChecked ? true : false
          }
        }
      )
    },
    toPrintMode() {
      this.printFlg = true
      this.setCheckboxStatuses()
    },
    cancelPrintMode() {
      this.printFlg = false
      this.checkboxStatuses = []
    },
    check({ isChecked, measurementResultId }) {
      const target = this.checkboxStatuses.find(
        checkboxStatus =>
          checkboxStatus.measurementResultId === measurementResultId
      )
      target.isChecked = isChecked
    },
    print() {
      const clinic = {
        ...this.clinic,
        prefectureName:
          this.getMasterDatum('prefectures', this.clinic.prefectureId)?.name ||
          ''
      }
      const speciesName = this.getSpecies(this.patient.speciesId).name
      const measurementResultsToPrint = this.filteredMeasurementResults
        .filter(measurementResult => {
          const target = this.checkboxStatuses.find(
            checkboxStatus =>
              checkboxStatus.measurementResultId === measurementResult.id
          )
          return target?.isChecked
        })
        .map(measurementResult => {
          const measurementResultItemsToPrint = this.makeMeasurementResultItemsToPrint(
            measurementResult
          )
          const examinationName = this.makeExaminationName(measurementResult)
          return {
            ...measurementResult,
            measurementResultItemsToPrint,
            examinationName
          }
        })
      printMeasurementResults({
        clinic,
        clinicImage: this.printSetting.image,
        showClinicName: Boolean(this.printSetting.printClinicNameFlg),
        owner: this.owner,
        patient: this.patient,
        speciesName,
        measurementResultsToPrint
      })
    },
    makeMeasurementResultItemsToPrint(measurementResult) {
      const measurementResultId = measurementResult.id
      const baseItems = this.getMeasurementResultItemsByMeasurementResultId(
        measurementResultId
      )
      return baseItems
        ? baseItems.map(v =>
            this.makeMeasurementResultItemToPrint(v, measurementResult)
          )
        : []
    },
    makeMeasurementResultItemToPrint(baseItem, measurementResult) {
      const itemRecord = this.getMasterLaboratoryItemIndexedByCode(
        baseItem.masterLaboratoryItemCode
      )
      if (!itemRecord) {
        return { ...baseItem, name: '', unit: '', referenceRange: '' }
      }
      const name = itemRecord.jaName
      const unit = this.makeMeasurementResultUnit(itemRecord)
      const referenceRange = this.makeMeasurementResultReferenceRange(
        itemRecord,
        measurementResult
      )
      return { ...baseItem, name, unit, referenceRange }
    },
    makeMeasurementResultUnit(itemRecord) {
      const unitRecord = this.getMasterLaboratoryItemUnitIndexedByCode(
        itemRecord.masterLaboratoryItemUnitCode
      )
      return unitRecord?.name || ''
    },
    makeMeasurementResultReferenceRange(itemRecord, measurementResult) {
      const rangeRecord = findMasterLaboratoryItemRange({
        masterLaboratoryItemCode: itemRecord.code,
        measurementResult
      })
      return rangeRecord
        ? rangeRecord.lowerLimit + '-' + rangeRecord.upperLimit
        : ''
    },
    makeExaminationName(measurementResult) {
      const masterLaboratoryCategoryCode = this.getMasterLaboratoryMachineIndexedByCode(
        measurementResult.masterLaboratoryMachineCode
      ).masterLaboratoryCategoryCode
      return (
        this.getMasterLaboratoryCategoryIndexedByCode(
          masterLaboratoryCategoryCode
        )?.name || ''
      )
    },
    openMeasurementResultPopup(measurementResult) {
      this.selectedMeasurementResultId = measurementResult.id
      this.showMeasurementResultPopup = true
    },
    closeMeasurementResultPopup() {
      this.showMeasurementResultPopup = false
      this.selectedMeasurementResultId = 0
    },
    closeAnnouncePopup() {
      this.announcePopup.isOpened = false
    }
  }
}
</script>

<style lang="scss" scoped>
.patient-measurement-results {
  display: inline-block;
  min-width: 100%;
  > .unlinked {
    display: flex;
    justify-content: flex-end;
  }
  > .owner-patient {
    margin-top: 15px;
  }
  > .linked {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .title {
      font-weight: bold;
      font-size: 15px;
    }
    > .buttons {
      display: flex;
      gap: 0 20px;
    }
  }
  > .search {
    margin-top: 15px;
  }
  > .search-result {
    margin-top: 30px;
    font-size: 13px;
  }
  > .list {
    margin-top: 15px;
    ::v-deep .table-body {
      max-height: 630px;
    }
  }
}
</style>
