<template>
  <div class="payment-owner-patient-detail">
    <div class="karte-info">
      <div class="row">
        <div class="item date">
          <div class="label">診療日</div>
          <div class="value" data-test="treatment-date">
            {{ makeTreatmentDate(this.medicalPayment) }}
          </div>
        </div>
        <div class="item patient">
          <div class="label">患者ID</div>
          <div class="value">
            <span class="show-id" data-test="patient-show-id">
              {{ patient.showId }}</span
            >
            <span class="name" data-test="patient-name">
              {{ patient.name }}</span
            >
          </div>
        </div>
        <div class="item owner">
          <div class="label">飼主ID</div>
          <div class="value">
            <span class="show-id" data-test="owner-show-id">
              {{ displayOwner.showId }}</span
            >
            <span class="name" data-test="owner-full-name">
              {{ displayOwner.fullName }}</span
            >
          </div>
        </div>
      </div>
      <div class="row">
        <div class="item date">
          <div class="label">発症日</div>
          <div class="value">
            <div data-test="onset-date">
              {{ makeOnsetDate(this.medicalPayment) }}
            </div>
          </div>
        </div>
        <div class="item disease">
          <div class="value" data-test="disease-name">
            {{ makeDiseaseName(this.medicalPayment) }}
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <base-button-register
        v-if="lookOnlyFlg === 0 && reservationButtonShowFlg"
        :text="'予約作成'"
        :styles="{ width: '120px', height: '30px' }"
        @click="makePatientReservation"
        data-test="make-patient-reservation"
      />
      <base-button-border-orange
        :styles="{ width: '120px', height: '30px' }"
        @click="goToKarte"
        data-test="go-to-karte"
        >カルテ</base-button-border-orange
      >
      <base-button-border-orange
        v-if="medicalPayment.applyFlg === 1"
        :styles="{ width: '120px', height: '30px' }"
        :disabled="medicalPayment.ipetCheckId > 0"
        @click="goToReport"
        data-test="go-to-report"
        >レセプト</base-button-border-orange
      >
      <base-button-small-red
        v-if="lookOnlyFlg === 0 && showFlg"
        :styles="{ width: '120px', height: '30px' }"
        :disabled="needsMiddleCalculateOrEndMedicalPaymentUpdateFlg"
        @click="$emit('redo')"
        data-test="redo"
        >再会計</base-button-small-red
      >
      <base-button-small-orange
        v-if="adjustableFlg"
        :styles="{ width: '120px', height: '30px' }"
        :disabled="needsMiddleCalculateOrEndMedicalPaymentUpdateFlg"
        @click="$emit('adjust')"
        data-test="adjust"
        >未収金精算</base-button-small-orange
      >
      <base-button-border-orange
        :disabled="!completeFlg"
        :styles="{ width: '120px', height: '30px' }"
        @click="openPrintPopup('receipt')"
        data-test="print-receipt"
        >領収書印刷</base-button-border-orange
      >
      <base-button-border-orange
        :disabled="
          allPrescriptionItems.length === 0 ||
            medicalPayment.middleCalculateFlg === 1
        "
        :styles="{ width: '120px', height: '30px' }"
        @click="openPrintPopup('prescription')"
        data-test="print-prescription"
        >処方箋印刷</base-button-border-orange
      >
      <base-button-border-orange
        :styles="{ width: '120px', height: '30px' }"
        @click="openPrintPopup('medicalPayment')"
        data-test="print-medical-payment"
        >診療明細印刷</base-button-border-orange
      >
    </div>
    <print-options-popup
      class="popup"
      v-if="printFlg"
      :dataType="printDataType"
      :data="printData"
      :owner="owner"
      :patient="patient"
      :removingPrintOptionKeys="removingPrintOptionKeys"
      @close="() => (printFlg = false)"
    />
  </div>
</template>

<script>
import BaseButtonRegister from '@/components/parts/atoms/BaseButtonRegister'
import BaseButtonBorderOrange from '@/components/parts/atoms/BaseButtonBorderOrange'
import BaseButtonSmallRed from '@/components/parts/atoms/BaseButtonSmallRed'
import BaseButtonSmallOrange from '@/components/parts/atoms/BaseButtonSmallOrange'
import PrintOptionsPopup from '@/components/popups/PrintOptionsPopup'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  name: 'PaymentOwnerPatientDetail',

  components: {
    BaseButtonRegister,
    BaseButtonBorderOrange,
    BaseButtonSmallRed,
    BaseButtonSmallOrange,
    PrintOptionsPopup
  },

  props: {
    owner: { type: Object, required: true },
    patient: { type: Object, required: true },
    medicalPayment: { type: Object, required: true },
    adjustableFlg: { type: Boolean, default: false },
    showFlg: { type: Boolean, default: false },
    completeFlg: { type: Boolean, default: false },
    lookOnlyFlg: { type: Number },
    reservationButtonShowFlg: { type: Boolean, default: false },
    needsMiddleCalculateOrEndMedicalPaymentUpdateFlg: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      printFlg: false,
      printDataType: '',
      printData: {},
      removingPrintOptionKeys: []
    }
  },

  computed: {
    ...mapGetters({
      getDisease: 'diseases/getDataById',
      getMedicalRecordByOriginalId: 'medicalRecords/getDataByOriginalId',
      getMedicalPaymentByOriginalId: 'medicalPayments/getDataByOriginalId',
      getTreatmentItemsByMedicalPaymentId:
        'medicalTreatmentItems/getDataByMedicalPaymentId',
      getTreatment: 'treatments/getDataById',
      getMedicine: 'medicines/getDataById',
      getStaff: 'staffs/getDataById',
      hospitalizations: 'hospitalizations/getData',
      getMedicalRecordById: 'medicalRecords/getDataById',
      getMedicalPaymentHistory: 'medicalPayments/getDataByOriginalIdIncludeDel',
      getTreatmentItemsByMedicalPaymentIdIncludeDel:
        'medicalTreatmentItems/getDataByMedicalPaymentIdIncludeDel'
    }),
    displayOwner() {
      const owner = this.owner
      if (owner) {
        return {
          ...owner,
          fullName: `${owner.lastName} ${owner.firstName}`
        }
      } else {
        return {}
      }
    },
    hospitalization() {
      return this.hospitalizations.find(h =>
        h.medicalPaymentOriginalIds.includes(this.medicalPayment.originalId)
      )
    },
    hospitalizationAllMedicalPayments() {
      if (
        this.medicalPayment.middleCalculateFlg ||
        (this.medicalPayment.endHospitalizationFlg &&
          !this.medicalPayment.startHospitalizationFlg)
      ) {
        return this.hospitalization.medicalPaymentOriginalIds
          .map(originalId => {
            const medicalPayment = this.getMedicalPaymentByOriginalId(
              originalId
            )
            const medicalRecord = this.getMedicalRecordByOriginalId(
              medicalPayment.medicalRecordOriginalId
            )
            const storeItems =
              this.getTreatmentItemsByMedicalPaymentId(medicalPayment.id) || []
            return {
              ...medicalPayment,
              dateStartTime: medicalRecord.date + medicalRecord.startTime,
              formatDateTime: `${moment(medicalRecord.date, 'YYYYMMDD').format(
                'Y年M月D日（dd）'
              )}${moment(medicalRecord.startTime, 'HHmm').format(
                'H:mm'
              )}~${moment(medicalRecord.endTime, 'HHmm').format('H:mm')}`,
              items: this.makeTreatmentItems(storeItems)
            }
          })
          .sort((a, b) => (a.dateStartTime < b.dateStartTime ? -1 : 1))
        //↑hospitalizationsのmedicalPaymentOriginalIdsは入院開始→入院中→退院の順番で入っていることを保証していないため並び替える
      } else {
        return null
      }
    },
    hospitalizationMedicalPayments() {
      if (
        this.medicalPayment.middleCalculateFlg ||
        (this.medicalPayment.endHospitalizationFlg &&
          !this.medicalPayment.startHospitalizationFlg)
      ) {
        const printMedicalPaymentOriginalIds = this.calculatePrintMedicalPaymentOriginalIds(
          this.medicalPayment,
          this.hospitalization
        )
        return this.hospitalizationAllMedicalPayments.filter(v =>
          printMedicalPaymentOriginalIds.includes(v.originalId)
        )
      } else {
        return null
      }
    },
    allTreatmentItems() {
      return this.makeTreatmentItemsForPrint(
        this.hospitalizationAllMedicalPayments
      )
    },
    treatmentItems() {
      return this.makeTreatmentItemsForPrint(
        this.hospitalizationMedicalPayments
      )
    },
    allPrescriptionItems() {
      return this.allTreatmentItems.filter(v => v.medicineId !== 0)
    },
    treatmentItemsAndTreatmentDates() {
      if (
        this.medicalPayment.middleCalculateFlg ||
        (this.medicalPayment.endHospitalizationFlg &&
          !this.medicalPayment.startHospitalizationFlg)
      ) {
        return this.hospitalizationMedicalPayments.reduce(
          (treatmentItemsAndTreatmentDates, medicalPayment) => {
            return medicalPayment.items.length > 0
              ? treatmentItemsAndTreatmentDates.concat([
                  { treatmentDate: medicalPayment.formatDateTime },
                  ...medicalPayment.items
                ])
              : treatmentItemsAndTreatmentDates
          },
          []
        )
      } else {
        return null
      }
    }
  },

  methods: {
    makeDiseaseName(medicalPayment) {
      if (medicalPayment) {
        const disease1 = this.getDisease(medicalPayment.disease1Id)
        const disease2 = this.getDisease(medicalPayment.disease2Id)
        return disease1 && disease2
          ? `${disease1.name}, ${disease2.name}`
          : disease1
          ? disease1.name
          : disease2
          ? disease2.name
          : '診断名なし'
      } else return ''
    },
    makeOnsetDate(medicalPayment) {
      if (medicalPayment.onsetDate) {
        const dateString = moment(medicalPayment.onsetDate, 'YYYYMMDD').format(
          'Y年M月D日（dd）'
        )
        return medicalPayment.onsetAroundFlg ? dateString + '頃' : dateString
      } else if (medicalPayment.uncertainOnsetFlg) {
        return '不明'
      } else {
        return ''
      }
    },
    makeTreatmentDate(medicalPayment) {
      if (medicalPayment) {
        const date = this.getMedicalRecordByOriginalId(
          medicalPayment.medicalRecordOriginalId
        ).date
        return moment(date, 'YYYYMMDD').format('Y年M月D日（dd）')
      } else return ''
    },
    makeTreatmentItems(items) {
      return items.map(item => {
        const name =
          this.getTreatment(item.treatmentId)?.name ||
          this.getMedicine(item.medicineId)?.name
        return { ...item, name }
      })
    },
    calculatePrintMedicalPaymentOriginalIds(medicalPayment, hospitalization) {
      if (medicalPayment.middleCalculateFlg) {
        //途中精算の診療明細
        return hospitalization.medicalPaymentOriginalIdsUntilMiddleCalculate
      } else {
        //退院の診療明細(日帰り入院ではない)
        if (
          hospitalization.middleCalculateMedialPaymentOriginalId &&
          hospitalization.middleCalculateDate &&
          hospitalization.medicalPaymentOriginalIdsUntilMiddleCalculate
        ) {
          //途中精算をしていた場合
          return hospitalization.medicalPaymentOriginalIds.filter(
            originalId =>
              !hospitalization.medicalPaymentOriginalIdsUntilMiddleCalculate.includes(
                originalId
              )
          )
        } else {
          //途中精算をしていない場合
          return hospitalization.medicalPaymentOriginalIds
        }
      }
    },
    makeTreatmentItemsForPrint(hospitalizationMedicalPayments) {
      if (
        this.medicalPayment.middleCalculateFlg ||
        (this.medicalPayment.endHospitalizationFlg &&
          !this.medicalPayment.startHospitalizationFlg)
      ) {
        return hospitalizationMedicalPayments.reduce(
          (treatmentItems, medicalPayment) => {
            return treatmentItems.concat(medicalPayment.items)
          },
          []
        )
      } else {
        const items =
          this.getTreatmentItemsByMedicalPaymentId(this.medicalPayment.id) || []
        return this.makeTreatmentItems(items)
      }
    },
    openPrintPopup(dataType, pastMedicalPayment, pastPaymentCreatedAt) {
      this.printDataType = dataType
      this.removingPrintOptionKeys = []
      this.printFlg = true
      if (dataType === 'receipt') {
        const taxExemptItemsFlg = this.treatmentItems.some(
          v => v.taxExemptFlg === 1
        )
        this.printData = {
          burdenAmount: this.medicalPayment.burdenAmount,
          taxExemptItemsFlg
        }
      } else if (dataType === 'prescription') {
        this.printData = {
          prescriptionItems: this.allPrescriptionItems,
          treatmentDate: this.makeTreatmentDate(this.medicalPayment)
        }
      } else if (dataType === 'medicalPayment') {
        if (pastMedicalPayment) {
          // 旧診療明細の印刷
          this.printData = this.makePastMedicalPaymentPrintData(
            pastMedicalPayment,
            pastPaymentCreatedAt
          )
          this.removingPrintOptionKeys = ['showNextReservation']
        } else {
          // (最新の)診療明細の印刷
          const staff = this.getStaff(this.medicalPayment.staffId)
          this.printData = {
            ...this.medicalPayment,
            diseaseName: this.makeDiseaseName(this.medicalPayment),
            date: this.makeTreatmentDate(this.medicalPayment),
            onsetDate: this.makeOnsetDate(this.medicalPayment),
            staffName: staff ? `${staff.lastName} ${staff.firstName}` : '',
            treatmentItems: this.treatmentItems,
            treatmentItemsAndTreatmentDates: this
              .treatmentItemsAndTreatmentDates,
            hospitalizationMedicalPayments: this.hospitalizationMedicalPayments,
            hospitalization: this.hospitalization
          }
        }
      }
    },
    makePastMedicalPaymentPrintData(pastMedicalPayment, pastPaymentCreatedAt) {
      const diseaseName = this.makeDiseaseName(pastMedicalPayment)
      const date = moment(
        this.getMedicalRecordById(pastMedicalPayment.medicalRecordId).date,
        'YYYYMMDD'
      ).format('Y年M月D日（dd）')
      const onsetDate = this.makeOnsetDate(pastMedicalPayment)
      const staff = this.getStaff(pastMedicalPayment.staffId)
      const staffName = staff ? `${staff.lastName} ${staff.firstName}` : ''
      if (
        pastMedicalPayment.middleCalculateFlg ||
        (pastMedicalPayment.endHospitalizationFlg &&
          !pastMedicalPayment.startHospitalizationFlg)
      ) {
        return this.makeHospitalizationPrintData(
          pastMedicalPayment,
          pastPaymentCreatedAt,
          diseaseName,
          date,
          onsetDate,
          staffName
        )
      } else {
        return this.makeOutpatientPrintData(
          pastMedicalPayment,
          diseaseName,
          date,
          onsetDate,
          staffName
        )
      }
    },
    makeHospitalizationPrintData(
      pastMedicalPayment,
      pastPaymentCreatedAt,
      diseaseName,
      date,
      onsetDate,
      staffName
    ) {
      const hospitalization = this.hospitalizations.find(h =>
        h.medicalPaymentOriginalIds.includes(pastMedicalPayment.originalId)
      )
      const printMedicalPaymentOriginalIds = this.calculatePrintMedicalPaymentOriginalIds(
        pastMedicalPayment,
        hospitalization
      )
      const hospitalizationMedicalPayments = printMedicalPaymentOriginalIds
        .flatMap(originalId => {
          const medicalPaymentHistory = this.getMedicalPaymentHistory(
            originalId
          )
            .flatMap(v => {
              return v.startHospitalizationFlg === 1 ||
                v.inHospitalFlg === 1 ||
                v.endHospitalizationFlg === 1
                ? {
                    ...v,
                    formatCreatedAt: moment(v.createdAt).format(
                      'YYYYMMDDHHmmss'
                    )
                  }
                : []
            })
            .sort((a, b) => (a.formatCreatedAt < b.formatCreatedAt ? 1 : -1))
          const medicalPayment = medicalPaymentHistory.find(
            v => v.formatCreatedAt < pastPaymentCreatedAt
          )
          if (!medicalPayment) {
            return []
          }
          const medicalRecord = this.getMedicalRecordById(
            medicalPayment.medicalRecordId
          )
          const storeItems =
            this.getTreatmentItemsByMedicalPaymentIdIncludeDel(
              medicalPayment.id
            ) || []
          return {
            ...medicalPayment,
            medicalRecordDate: medicalRecord.date,
            dateStartTime: medicalRecord.date + medicalRecord.startTime,
            formatDateTime: `${moment(medicalRecord.date, 'YYYYMMDD').format(
              'Y年M月D日（dd）'
            )}${moment(medicalRecord.startTime, 'HHmm').format(
              'H:mm'
            )}~${moment(medicalRecord.endTime, 'HHmm').format('H:mm')}`,
            items: this.makeTreatmentItems(storeItems)
          }
        })
        .sort((a, b) => (a.dateStartTime < b.dateStartTime ? -1 : 1))
      //↑hospitalizationsのmedicalPaymentOriginalIdsは入院開始→入院中→退院の順番で入っていることを保証していないため並び替える
      const treatmentItems = hospitalizationMedicalPayments.reduce(
        (treatmentItems, medicalPayment) => {
          return treatmentItems.concat(medicalPayment.items)
        },
        []
      )
      const treatmentItemsAndTreatmentDates = hospitalizationMedicalPayments.reduce(
        (treatmentItemsAndTreatmentDates, medicalPayment) => {
          return medicalPayment.items.length > 0
            ? treatmentItemsAndTreatmentDates.concat([
                { treatmentDate: medicalPayment.formatDateTime },
                ...medicalPayment.items
              ])
            : treatmentItemsAndTreatmentDates
        },
        []
      )
      return {
        ...pastMedicalPayment,
        diseaseName,
        date,
        onsetDate,
        staffName,
        treatmentItems,
        treatmentItemsAndTreatmentDates,
        hospitalizationMedicalPayments: hospitalizationMedicalPayments,
        hospitalization: {
          startDate: hospitalizationMedicalPayments[0].medicalRecordDate,
          endDate:
            hospitalizationMedicalPayments[
              hospitalizationMedicalPayments.length - 1
            ].medicalRecordDate
        }
      }
    },
    makeOutpatientPrintData(
      pastMedicalPayment,
      diseaseName,
      date,
      onsetDate,
      staffName
    ) {
      const items =
        this.getTreatmentItemsByMedicalPaymentIdIncludeDel(
          pastMedicalPayment.id
        ) || []
      return {
        ...pastMedicalPayment,
        diseaseName,
        date,
        onsetDate,
        staffName,
        treatmentItems: this.makeTreatmentItems(items),
        treatmentItemsAndTreatmentDates: null,
        hospitalizationMedicalPayments: null,
        hospitalization: null
      }
    },
    goToKarte() {
      this.$router.push({
        name: 'medical-record-edit',
        params: {
          ownerId: this.owner.id,
          patientId: this.patient.id,
          originalId: this.medicalPayment.medicalRecordOriginalId
        }
      })
    },
    makePatientReservation() {
      const diffMinutes = this.$store.getters['reservationSetting/getData']
        .reservationTimeUnit
      this.$store.dispatch('timeTable/setInitialReservation', {
        id: 0,
        ownerId: this.owner.id,
        patientId: this.patient.id,
        startTime:
          moment()
            .add(1, 'h')
            .format('HH') + '00',
        endTime:
          moment()
            .add(1, 'h')
            .format('HH') + `${diffMinutes}`,
        ownerPatientPageFlg: true
      })
      this.$router.push({
        name: 'main',
        params: {
          fromPage: 'payment-show',
          paymentShowPageIdInfo: {
            ownerId: this.owner.id,
            patientId: this.patient.id,
            medicalPaymentOriginalId: this.medicalPayment.originalId
          }
        }
      })
    },
    goToReport() {
      this.$router.push({
        name: 'anicom-report-show',
        params: { medicalPaymentId: this.medicalPayment.id }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-owner-patient-detail {
  box-sizing: border-box;
  border: solid 1px #{$light-grey};
  background-color: #{$white_f7};
  padding: 12px 15px;
  > .karte-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 12px;
    > .row {
      display: flex;
      gap: 15px;
      > .item {
        display: flex;
        gap: 10px;
        &.date {
          min-width: 178px;
        }
        > .label {
          width: 40px;
        }
        > .value {
          flex: 1;
          font-feature-settings: 'palt';
          > .name {
            font-weight: bold;
            margin-left: 10px;
          }
        }
      }
    }
  }
  > .buttons {
    margin-top: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  }
}
</style>
